import inapp from "./inapp";
import _sodium from "libsodium-wrappers";

const Account = {
  async register(email, username, password) {
    return await inapp.post("v1/auth/register", {
      email,
      username,
      password,
    });
  },

  async login({ username, password }) {
    return await inapp.post("v1/auth/login", {
      username,
      password,
    });
  },

  async logout() {
    return await inapp.post("v1/auth/logout", {});
  },

  async status() {
    return await inapp.get("v1/auth/status", {});
  },

  async getUser() {
    return await inapp.get("v1/auth/user/getUser", {});
  },

  async changePassword(oldPassword, newPassword) {
    return await inapp.post("v1/auth/changePassword", {
      oldPassword,
      newPassword,
    });
  },

  async uploadAvatar(data) {
    return await inapp.post("v1/auth/user/uploadAvatar", data, {});
  },

  async updateUser(update) {
    return await inapp.put("v1/auth/user/updateUser", {
      update,
    });
  },

  async createKey(title, publicKey) {
    return await inapp.post(`v1/auth/user/createkey`, {
      title,
      key: publicKey,
    });
  },

  async deleteKey(id) {
    return await inapp.post(`v1/auth/user/deletekey`, {
      id,
    });
  },

  async getKeyList() {
    return await inapp.get(`v1/auth/user/getKeyList`, {});
  },

  // async generateKeyPair(keyLength) {
  //   return await inapp.get('v1/auth/user/generateKeyPair', {
  //     params: {
  //       keyLength
  //     }
  //   });
  // },

  async generateKeyPair() {
    await _sodium.ready;
    const sodium = _sodium;
    return sodium.crypto_kx_keypair();
  },
};

export default Account;
