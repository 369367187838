import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';

/* store */
import store from './store';
/* global */
import 'assets/scss/material-dashboard-pro-react.scss?v=1.9.0';

console.log('INAPI: ', process.env.REACT_APP_INAPI);
const hist = createBrowserHistory();

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={promise}>
      <Router history={hist}>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </Router>
    </Elements>
  </Provider>,
  document.getElementById('root')
);
