import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class CreateDataAccessWizardStep1 extends React.Component {
  state = {
    title: "",
    titleState: "",
    description: "",
    requestKey: "",
    requestKeyState: "",
    bonusAmount: "",
    bonusAmountState: "",
    bonusEach: "",
    bonusEachState: "",
    quality: "all",
    qualityState: "",
    access: "Single Access",
    accessState: "",
  };

  componentDidUpdate(prevProps) {
    if (prevProps.request !== this.props.request && prevProps.request) {
      const {
        title,
        desc: description,
        entity,
        key,
        quality,
        access,
      } = prevProps.request;
      this.setState({
        title,
        description: description || "",
        requestKey: key || "",
        bonusAmount: entity && entity.bonus_amount ? entity.bonus_amount : "",
        bonusEach: entity && entity.bonus_each ? entity.bonus_each : "",
        quality: quality || "all",
        access: access || "Single Access",
      });
    }
  }

  /* 发送结果 */
  sendState() {
    return this.state;
  }

  /* 是否允许下一步 */
  isValidated() {
    const { title, requestKey, bonusAmount, bonusEach, access } = this.state;
    let hasError = false;

    this.setState({
      titleState: "",
      bonusAmountState: "",
      bonusEachState: "",
    });
    if (title.trim() === "") {
      hasError = true;
      this.setState({ titleState: "error" });
    }
    if (access.trim() === "") {
      hasError = true;
      this.setState({ accessState: "error" });
    }
    if (requestKey.trim() === "") {
      hasError = true;
      this.setState({ requestKeyState: "error" });
    }
    if (!/^\d+(\.\d+)?$/.test(bonusAmount.trim())) {
      hasError = true;
      this.setState({ bonusAmountState: "error" });
    }
    if (!/^\d+(\.\d+)?$/.test(bonusEach.trim())) {
      hasError = true;
      this.setState({ bonusEachState: "error" });
    }
    if (hasError) {
      return false;
    }
    return true;
  }

  render() {
    const { classes, requestKeys } = this.props;
    const {
      title,
      titleState,
      description,
      requestKey,
      requestKeyState,
      bonusAmount,
      bonusAmountState,
      bonusEach,
      bonusEachState,
      quality,
      qualityState,
      access,
      accessState,
    } = this.state;
    const isEdit = !!this.props.request;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>Title</FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <CustomInput
              error={titleState === "error"}
              formControlProps={{ fullWidth: true }}
              labelText={<span>(required)</span>}
              inputProps={{
                type: "text",
                value: title,
                onChange: (e) => {
                  this.setState({ title: e.target.value });
                },
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>Category</FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <div className={classes.staticFormGroup}>
              <p
                className={classes.staticFormControl}
                style={{ marginTop: 23 }}
              >
                Default
              </p>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Description
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{
                type: "text",
                value: description,
                onChange: (e) => {
                  this.setState({ description: e.target.value });
                },
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              User Quality
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Choose User Quality...
              </InputLabel>
              <Select
                disabled={isEdit}
                error={qualityState === "error"}
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={quality}
                onChange={(e) => {
                  this.setState({ quality: e.target.value });
                }}
                inputProps={{
                  name: "qualitySelect",
                  id: "quality-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose User Quality...
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="all"
                >
                  All
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="high"
                >
                  High
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Access Type
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Choose Access Type...
              </InputLabel>
              <Select
                disabled={isEdit}
                error={accessState === "error"}
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={access}
                onChange={(e) => {
                  this.setState({ access: e.target.value });
                }}
                inputProps={{
                  name: "qualitySelect",
                  id: "quality-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Access Type...
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Single Access"
                >
                  Single Access
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Multiple Access"
                >
                  Multiple Access
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Request Key
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Choose Key...
              </InputLabel>
              <Select
                error={requestKeyState === "error"}
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={requestKey}
                onChange={(e) => {
                  this.setState({ requestKey: e.target.value });
                }}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Key...
                </MenuItem>
                {requestKeys.map((key) => (
                  <MenuItem
                    key={key.id}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={key.id}
                  >
                    {key.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>Bonus</FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <div className={classes.staticFormGroup}>
              <p
                className={classes.staticFormControl}
                style={{ marginTop: 23 }}
              >
                IPT Tokens
              </p>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Total IPTs
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <CustomInput
              error={bonusAmountState === "error"}
              formControlProps={{ fullWidth: true }}
              labelText={<span>(required)</span>}
              inputProps={{
                type: "text",
                value: bonusAmount,
                onChange: (e) => {
                  this.setState({ bonusAmount: e.target.value });
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <FormLabel className={classes.labelHorizontal}>
              IPTs per Response
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <CustomInput
              error={bonusEachState === "error"}
              formControlProps={{ fullWidth: true }}
              labelText={<span>(required)</span>}
              inputProps={{
                type: "text",
                value: bonusEach,
                onChange: (e) => {
                  this.setState({ bonusEach: e.target.value });
                },
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(regularFormsStyle)(CreateDataAccessWizardStep1);
