import React from "react";
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const Uploadform = (props) => (
    <div>
    {/*<GridContainer justify='center'>
      <GridItem>
        Please download the encrypted file after it has been uploaded locally. 
      </GridItem>
    </GridContainer> */}
    <GridContainer justify='center'>
      <GridItem> 
        <CustomInput
          success={props.campaignnamestate === 'success'}
          error={props.campaignnamestate === 'error'}
          labelText='Data Set Name'
          id='campaign_name'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            onChange: (e) => {
              if (
                props.verifylength(e.target.value, 1) &&
                !props.dataset.find(
                  (element) => element.name === e.target.value
                )
              ) {
                props.setcampaignnamestate('success');
              } else {
                props.setcampaignnamestate('error');
              }
              props.setcampaignname(e.target.value);
            },
          }}
        />
      </GridItem>
    </GridContainer> 
    <GridContainer justify='center'>
      <GridItem> 
        <Button color='primary' onClick={props.handleupload}>
          Upload
        </Button>
      </GridItem>
    </GridContainer> 
  </div>
)

export default Uploadform;