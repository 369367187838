import { VCR } from '@/apis';

export const getRoomsRequest = () => ({ type: 'GET_ROOMS_REQUEST' });

export const getRoomsSuccess = (rooms) => ({
  type: 'GET_ROOMS_SUCCESS',
  payload: rooms,
});

export const getRoomsFailure = (error) => ({
  type: 'GET_ROOMS_FAILURE',
  payload: error,
});

export const getRooms = () => {
  return async (dispatch) => {
    dispatch(getRoomsRequest());
    try {
      const response = await VCR.getRooms();
      dispatch(getRoomsSuccess(response.data.vcrRooms));
    } catch (error) {
      dispatch(getRoomsFailure(error));
    }
  };
};

export const createRoomRequest = (room) => ({
  type: 'CREATE_ROOM_REQUEST',
  payload: room,
});

export const createRoomSuccess = (room, key, users) => ({
  type: 'CREATE_ROOM_SUCCESS',
  payload: [room, key, users],
});

export const createRoomFailure = (error) => ({
  type: 'CREATE_ROOM_FAILURE',
  payload: error,
});

export const createRoom = (name, selectedKey, recipients, userId) => {
  return async (dispatch) => {
    dispatch(createRoomRequest(name));
    try {
      const response_createRoom = await VCR.createRoom(name);
      await VCR.createAESKey(response_createRoom.data[0].id, selectedKey);
      const response_createRoomUsers = await VCR.createRoomUsers(
        response_createRoom.data[0].id,
        recipients,
        userId
      );

      dispatch(
        createRoomSuccess(
          response_createRoom.data.vcrRooms,
          selectedKey,
          response_createRoomUsers[0]
        )
      );
      dispatch(getRooms());
    } catch (error) {
      dispatch(createRoomFailure(error));
    }
  };
};

export const updateAesKeyRequest = () => ({ type: 'UPDATE_AES_KEY_REQUEST' });

export const updateAesKeySuccess = (something) => ({
  type: 'UPDATE_AES_KEY_SUCCESS',
  payload: something,
});

export const updateAesKeyFailure = (error) => ({
  type: 'UPDATE_AES_KEY_FAILURE',
  payload: error,
});

export const updateAesKey = (userId,room_id, selectedKey, encryptedSessionKey) => {
  return async (dispatch) => {
    dispatch(updateAesKeyRequest());
    try {
      await VCR.updateAesKey(
        userId,
        room_id,
        selectedKey,
        encryptedSessionKey
      );
      dispatch(updateAesKeySuccess(selectedKey));
    } catch (error) {
      dispatch(updateAesKeyFailure(error));
    }
  };
};
export const updateSessionKeyRequest = () => ({
  type: 'UPDATE_SESSION_KEY_REQUEST',
});

export const updateSessionKeySuccess = (something) => ({
  type: 'UPDATE_SESSION_KEY_SUCCESS',
  payload: something,
});

export const updateSessionKeyFailure = (error) => ({
  type: 'UPDATE_SESSION_KEY_FAILURE',
  payload: error,
});

export const updateSessionKey = (userId, room_id, selectedKey, encryptedSessionKey) => {
  return async (dispatch) => {
    dispatch(updateSessionKeyRequest());
    try {
      await VCR.updateSessionKey(
        userId,
        room_id,
        selectedKey,
        encryptedSessionKey
      );
      dispatch(updateSessionKeySuccess(selectedKey));
      dispatch(getRooms());
    } catch (error) {
      dispatch(updateSessionKeyFailure(error));
    }
  };
};

export const getOwnerIdRequest = () => ({ type: 'GET_OWNER_ID_REQUEST' });

export const getOwnerIdSuccess = (something) => ({
  type: 'GET_OWNER_ID_SUCCESS',
  payload: something,
});

export const getOwnerIdFailure = (error) => ({
  type: 'GET_OWNER_ID_FAILURE',
  payload: error,
});

export const getOwnerId = (room_id) => {
  return async (dispatch) => {
    dispatch(getOwnerIdRequest());
    try {
      const response = await VCR.getOwnerId(room_id);
      dispatch(getOwnerIdSuccess(response));
    } catch (error) {
      dispatch(getOwnerIdFailure(error));
    }
  };
};

export const getDataSetRequest = () => ({ type: 'GET_DATASET_REQUEST'});

export const getDataSetSuccess = dataSet => ({
  type: 'GET_DATASET_SUCCESS',
  payload: dataSet,
});

export const getDataSetFailure = error => ({
  type: 'GET_DATASET_FAILURE',
  payload: error
});

export const getDataSet = (roomId) => {
  return async dispatch => {
    dispatch(getDataSetRequest());
    try {
      const response = await VCR.getDataSet(roomId);
      dispatch(getDataSetSuccess(response));
    } catch (error) {
      dispatch(getDataSetFailure(error));
    }
  }
};

export const isRoomOwnerRequest = () => ({ type: 'IS_ROOM_OWNER_REQUEST'});

export const isRoomOwnerSuccess = isRoomOwner => ({
  type: 'IS_ROOM_OWNER_SUCCESS',
  payload: isRoomOwner
});

export const isRoomOwnerFailure = error => ({
  type: 'IS_ROOM_OWNER_FAILURE',
  payload: error
});

export const isRoomOwner = roomId => {
  return async dispatch => {
    dispatch(isRoomOwnerRequest());
    try {
      const response = await VCR.getUserType(roomId);
      dispatch(isRoomOwnerSuccess(response.data.resp[0].type === 'Owner'));
    } catch (error) {
      dispatch(isRoomOwnerFailure(error));
    }
  }
};

export const addNotificationRequest = () => ({
  type: 'NOTIFICATION_REQUEST',
});

export const addNotificationSuccess = (something) => ({
  type: 'NOTIFICATION_SUCCESS',
});

export const addNotificationFailure = (error) => ({
  type: 'NOTIFICATION_FAILURE',
  payload: error,
});

export const addNotification = (notification, owner_id) => {
  return async (dispatch) => {
    dispatch(addNotificationRequest());
    try {
      const response = await VCR.addNotification(notification, owner_id);
      dispatch(addNotificationSuccess(response));
    } catch (error) {
      dispatch(addNotificationFailure(error));
    }
  };
};

export const addGuestRequest = () => ({ type: 'ADD_GUEST_REQUEST' });

export const addGuestSuccess = (something) => ({
  type: 'ADD_GUEST_SUCCESS',
});

export const addGuestFailure = (error) => ({
  type: 'ADD_GUEST_FAILURE',
  payload: error,
});

export const addGuest = (room_id, receivers) => {
  return async (dispatch) => {
    dispatch(addGuestRequest());
    try {
      const response = await VCR.addGuest(room_id, receivers);
      dispatch(addGuestSuccess(response));
    } catch (error) {
      dispatch(addGuestFailure(error));
    }
  };
};

export const reinitializeRoomsState = () => ({
  type: 'REINITIALIZE_STATE',
});
