import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from '@/reducers';

const NODE_ENV = process.env.NODE_ENV || 'development';
const store = createStore(reducers, NODE_ENV === 'development' ? composeWithDevTools(
  applyMiddleware(thunk)
) : applyMiddleware(thunk));

export default store;
