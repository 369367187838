import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import { ruRU } from '@material-ui/core/locale';

const InitialConfig = MaterialConfig;

export const buildConfigFields = (tableName, columns) => {
  if (columns) {
    let subfields = {};
    for (let i = 0; i < columns.length; i++) {
      subfields[columns[i]] = {
        type: 'text',
        valueSources: ['field'],
        excludeOperators: [
          'proximity',
          'not_equal',
          'like',
          'not_like',
          'starts_with',
          'ends_with',
          'is_empty',
          'is_not_empty',
        ],
      };
    }

    const config = {
      [tableName]: {
        type: '!struct',
        subfields: subfields,
      },
    };
    return config;
  }
};

const conjunctions = {
  AND: InitialConfig.conjunctions.AND,
  OR: InitialConfig.conjunctions.OR,
};

const operators: Operators = {
  ...InitialConfig.operators,
};

const widgets = {
  ...InitialConfig.widgets,
  // overriding
  date: {
    ...InitialConfig.widgets.date,
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD',
  },
  time: {
    ...InitialConfig.widgets.time,
    timeFormat: 'HH:mm',
    valueFormat: 'HH:mm:ss',
  },
  datetime: {
    ...InitialConfig.widgets.datetime,
    timeFormat: 'HH:mm',
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  treeselect: {
    ...InitialConfig.widgets.treeselect,
    customProps: {
      showSearch: true,
    },
  },
};

const types = {
  ...InitialConfig.types,
};

const localeSettings = {
  locale: {
    moment: 'ru',
    material: ruRU,
  },
  valueLabel: 'Value',
  valuePlaceholder: 'Value',
  fieldLabel: 'Field',
  operatorLabel: 'Operator',
  fieldPlaceholder: 'Select field',
  operatorPlaceholder: 'Select operator',
  deleteLabel: null,
  addGroupLabel: 'Add group',
  addRuleLabel: 'Add rule',
  delGroupLabel: null,
  notLabel: 'Not',
  valueSourcesPopupTitle: 'Select value source',
  removeRuleConfirmOptions: {
    title: 'Are you sure delete this rule?',
    okText: 'Yes',
    okType: 'danger',
  },
  removeGroupConfirmOptions: {
    title: 'Are you sure delete this group?',
    okText: 'Yes',
    okType: 'danger',
  },
};

const settings = {
  ...InitialConfig.settings,
  ...localeSettings,

  valueSourcesInfo: {
    value: {
      label: 'Value',
    },
    field: {
      label: 'Field',
      widget: 'field',
    },
    func: {
      label: 'Function',
      widget: 'func',
    },
  },
  // canReorder: false,
  // canRegroup: false,
  // showNot: false,
  // showLabels: true,
  maxNesting: 3,
  canLeaveEmptyGroup: true, //after deletion

  // renderField: (props) => <FieldCascader {...props} />,
  // renderOperator: (props) => <FieldDropdown {...props} />,
  // renderFunc: (props) => <FieldSelect {...props} />,
};

const funcs = {};

const config = {
  conjunctions,
  operators,
  widgets,
  types,
  settings,
  funcs,
};

export default config;
