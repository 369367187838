import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Table from "components/Table/Table";

import CheckoutForm from "inComponents/CheckoutForm";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import { updateUser } from "@/actions";

const useStyles = makeStyles(styles);

export default function CheckoutPage(props) {
  const classes = useStyles();
  const [price, setPrice] = useState(0);
  const [item, setItem] = useState("");
  const [purchasedCredit, setPurchasedCredit] = useState(0);
  const existingCredit = useSelector((state) => state.profile.me.credit);
  const dispatch = useDispatch();

  useEffect(() => {
    switch (props.match.params.item) {
      case "5credits": {
        setPrice(0);
        setPurchasedCredit(5);
        setItem("Free Plan - 5 IPT Tokens");
        break;
      }
      case "10credits": {
        setPrice(10);
        setPurchasedCredit(10);
        setItem("$10 USD - 10 IPT Tokens");
        break;
      }
      case "55credits": {
        setPrice(50);
        setPurchasedCredit(55);
        setItem("$50 USD - 55 IPT Tokens");
        break;
      }
      case "120credits": {
        setPrice(100);
        setPurchasedCredit(120);
        setItem("$100 USD - 120 IPT Tokens");
        break;
      }
      default: {
        setPrice(0);
        setPurchasedCredit(5);
        setItem("Free Plan - 5 IPT Tokens");
        break;
      }
    }
  }, [props.match.params.item]);

  const setUserCredit = () => {
    const credit = existingCredit + purchasedCredit;
    dispatch(updateUser({ credit }));
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Checkout</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={["PRODUCT", "PRICE", "QUANTITY", "AMOUNT", ""]}
              tableData={[
                [
                  <span key="key">
                    <p className={classes.tdNameAnchor}>{item}</p>
                  </span>,
                  `$${price}`,
                  "1",
                  `$${price}`,
                ],
                {
                  total: true,
                  colspan: "3",
                  amount: (
                    <span key="key">
                      <small>$</small>
                      {price}
                    </span>
                  ),
                },
              ]}
              tableShopping
              customHeadCellClasses={[
                classes.description,
                classes.right,
                classes.right,
                classes.right,
              ]}
              customHeadClassesForCells={[0, 1, 2, 3]}
              customCellClasses={[
                classes.tdName,
                classes.tdNumber,
                classes.tdNumber,
                classes.tdNumber,
              ]}
              customClassesForCells={[0, 1, 2, 3]}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridContainer>
        <GridItem md={4} />
        <GridItem md={4}>
          <CheckoutForm
            items={[props.match.params.item]}
            currency={props.match.params.currency}
            callback={setUserCredit}
            purchased_credit={purchasedCredit}
          />
        </GridItem>
        <GridItem md={4} />
      </GridContainer>
    </GridContainer>
  );
}
