const INITIAL_STATE = {
  list: [],
  createError: false,
  createErrorMessage: {},
  createSuccess: false,
  deleteError: false,
  deleteErrorMessage: {},
  deleteSuccess: false,
  getError: false,
  getErrorMessage: {},
  getSuccess: false,
  loading: false,
};

const keysReducer = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REINITIALIZE_STATE':
      return {
        ...currentState,
        createError: false,
        createErrorMessage: {},
        createSuccess: false,
        deleteError: false,
        deleteErrorMessage: {},
        deleteSuccess: false,
        getError: false,
        getErrorMessage: {},
        getSuccess: false,
        loading: false,
      };
    case 'GET_KEYS_REQUEST':
      return {
        ...currentState,
        getSuccess: false,
        getError: false,
        getErrorMessage: {},
        loading: true,
      };
    case 'GET_KEYS_SUCCESS':
      return {
        ...currentState,
        list: action.payload,
        getSuccess: true,
        getError: false,
        getErrorMessage: {},
        loading: false,
      };
    case 'GET_KEYS_FAILED':
      return {
        ...currentState,
        list: [],
        getSuccess: false,
        getError: true,
        getErrorMessage: action.payload,
        loading: false,
      };
    case 'CREATE_KEY_REQUEST':
      return {
        ...currentState,
        createError: false,
        createErrorMessage: {},
        createSuccess: false,
        loading: true,
      };
    case 'CREATE_KEY_SUCCESS':
      return {
        ...currentState,
        list: [...currentState.list, action.payload],
        createError: false,
        createErrorMessage: {},
        createSuccess: true,
        loading: false,
      };
    case 'CREATE_KEY_FAILED':
      return {
        ...currentState,
        createError: true,
        createErrorMessage: action.payload,
        createSuccess: true,
        loading: false,
      };
    case 'DELETE_KEY_REQUEST':
      return {
        ...currentState,
        deleteError: false,
        deleteErrorMessage: {},
        deleteSuccess: false,
        loading: true
      };
    case 'DELETE_KEY_SUCCESS':
      return {
        ...currentState,
        list: action.payload,
        deleteError: false,
        deleteErrorMessage: {},
        deleteSuccess: true,
        loading: false
      };
    case 'DELETE_KEY_FAILED':
      return {
        ...currentState,
        deleteError: true,
        deleteErrorMessage: action.payload,
        deleteSuccess: false,
        loading: false
      };
    default: return currentState;
  }
};

export default keysReducer;