// Click on analyze to land here for surveys.
// Card 1 : Shows the hiogh level details.
// Card 2 ; Visual representation (bar chart for each question)

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline';
import { Request } from '@/apis';

// core components
import Table from 'components/Table/Table';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/chartsStyle.js';

const useStyles = makeStyles(styles);
function SurveyDetail(props) {
  const params = useParams();
  const classes = useStyles();

  const requestID = params.id;

  const [requestData, setRequestData] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [dataResponse, setData] = useState([]);
  useEffect(() => {
    async function surveyDetails() {
      const question = await Request.getSurveyQuestions(requestID); // gives all the questions in that survey requests
      const questionsArray = question.data.payload;

      setQuestions(questionsArray);
      let newtemp = [];
      for (let i = 0; i < questionsArray.length; i++) {
        const optionsarray = JSON.parse(questionsArray[i].data);
        let temp = [];
        for (let j = 0; j < optionsarray.length; j++) {
          const response = await Request.getCount(
            questionsArray[i].id,
            optionsarray[j]
          );
          const newObj = {
            option: optionsarray[j],
            counter: response.data.payload,
          };
          temp[j] = newObj;
        }
        newtemp.push(temp);
        setRequestData(props);
      }
      setData(newtemp);
    }
    surveyDetails();
  }, [props, requestID]);

  const _tableData = () => {
    let rows = [];

    if (requestData != null) {
      const reqData = requestData.location.state.data;
      rows.push([
        <div>
          <h4>
            <b>{reqData.title}</b>
          </h4>
        </div>,
        <div>{reqData.type_name}</div>,
        <div>{reqData.key}</div>,
        <div>{reqData.created}</div>,
        <div>{reqData.desc}</div>,
      ]);
    }
    return rows;
  };

  const makeGraph = (quest, index) => {
    let xAxis = [];
    let yAxis = [];
    let newy = [];
    if (dataResponse.length > 0) {
      for (let i = 0; i < dataResponse[index].length; i++) {
        xAxis[i] = dataResponse[index][i].option;
        yAxis[i] = dataResponse[index][i].counter;
      }
      newy = yAxis;
      return {
        labels: xAxis,
        series: newy,
      };
    }
  };

  const chartOptions = () => {
    return {
      height: '230px',
    };
  };

  const getData = () => {
    let rows = [];
    if (questions.length > 0 && requestData != null) {
      for (let i = 0; i < questions.length; i++) {
        rows.push([
          <Card>
            <CardHeader color='danger' icon>
              <CardIcon color='danger'>
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Pie Chart</h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={makeGraph(questions[i], i)}
                type='Pie'
                options={chartOptions()}
              />
              <CardBody>
                <h4 className={classes.cardTitle}>{questions[i].title}</h4>
              </CardBody>
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              {
                // <h6 className={classes.legendTitle}>Legend</h6>
                // <i className={'fas fa-circle ' + classes.info} /> Apple{` `}
                // <i className={'fas fa-circle ' + classes.warning} /> Samsung
                // {` `}
                // <i className={'fas fa-circle ' + classes.danger} /> Windows Phone
                // {` `}
              }
            </CardFooter>
          </Card>,
        ]);
      }
      return rows;
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          <CardHeader>High Level Details</CardHeader>
          <Table
            tableHead={['Title', 'Type', 'Key', 'Created', 'Description']}
            tableData={_tableData()}
          />
          <div></div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>{getData()}</CardBody>
      </Card>
    </div>
  );
}

export default SurveyDetail;
// >>>>>>> 2ace10684990815b61c29b7c5fd159ea7551b1e1
