import inapp from "./inapp";

const Request = {
  async getOverview() {
    return await inapp.get("v1/request/today");
  },

  async getList(page, limit, type) {
    return await inapp.get("v1/request/list", {
      params: {
        page,
        limit,
        type,
      },
    });
  },

  async create(type, form) {
    return await inapp.post("v1/request/create", {
      type,
      title: form.title,
      description: form.description || "",
      key: form.requestKey || "",
      bonus: "eth",
      bonus_amount: form.bonusAmount || null,
      bonus_each: form.bonusEach || null,
      quality: form.quality || "all",
      status: form.isDraft ? 0 : 1,
      access: form.access,
      contract_id: form.newchild,
      collectionData: form.data,
      address: form.addressSigner || null,
    });
  },

  async edit(type, id, form) {
    return await inapp.post("request.api/edit", {
      id,
      type,
      title: form.title,
      desc: form.description || "",
      key: form.requestKey || "",
      bonus: "eth",
      bonus_amount: form.bonusAmount || null,
      bonus_each: form.bonusEach || null,
      status: form.isDraft ? 0 : 1,
      access: form.access,
      contract_id: form.newchild,
      collectionData: form.data,

      address: form.addressSigner || null,
    });
  },

  async delete(type, id) {
    return await inapp.post("request.api/delete", {
      type,
      id,
    });
  },

  async getRequest(type, id) {
    return await inapp.get("v1/request/get", {
      params: {
        type,
        id,
      },
    });
  },

  async getResponse(type, id, offset) {
    return await inapp.get("request.api/response", {
      type,
      id,
      offset,
    });
  },

  async rankResponse(index, stars) {
    return await inapp.post("request.api/ranking", {
      index,
      stars,
    });
  },

  async getStatistic(id) {
    return await inapp.get("request.api/statistic", {
      id,
    });
  },

  async getEndUserData(requestID) {
    return await inapp.get("v1/requestClient/getEndUserData", {
      params: {
        requestID,
      },
    });
  },

  async getSurveyQuestions(requestID) {
    return await inapp.get("v1/requestClient/getSurveyQuestions", {
      params: {
        requestId: requestID,
      },
    });
  },

  async getCount(questionId, responseAnswer) {
    return await inapp.get("v1/requestClient/getCount", {
      params: {
        questionId,
        responseAnswer,
      },
    });
  },

  async getContractId(requestId) {
    return await inapp.get("v1/requestClient/getContractId", {
      params: {
        requestId,
      },
    });
  },

  async checkSubmitted(requestId, deviceId) {
    return await inapp.get("v1/requestClient/checkSubmitted", {
      params: {
        requestId,
        deviceId,
      },
    });
  },

  async getContracts(page, limit) {
    return await inapp.get("v1/request/contracts", {
      params: {
        page,
        limit,
      },
    });
  },
};

export default Request;
