import { Account } from '@/apis';

export const getKeysRequest = () => ({ type: 'GET_KEYS_REQUEST' });

export const getKeysSuccess = (keys) => ({
  type: 'GET_KEYS_SUCCESS',
  payload: keys,
});

export const getKeysFailed = (error) => ({
  type: 'GET_KEYS_FAILED',
  payload: error,
});

export const getKeys = () => {
  return async (dispatch) => {
    dispatch(getKeysRequest());

    await Account.getKeyList()
      .then(({ data }) => {
        const keys = data.list.map((key) => {
          const privateKey = localStorage.getItem(`private_${key.id}`);
          return {
            id: key.id,
            publicKey: key.key,
            privateKey,
            title: key.title,
            createdAt: key.createdAt,
          };
        });
        dispatch(getKeysSuccess(keys));
      })
      .catch((error) => {
        dispatch(getKeysFailed(error));
      });
  };
};

export const createKeySuccess = (key) => ({
  type: 'CREATE_KEY_SUCCESS',
  payload: key,
});

export const createKeyFailed = (error) => ({
  type: 'CREATE_KEY_FAILED',
  payload: error,
});

export const createKeyRequest = () => ({
  type: 'CREATE_KEY_REQUEST',
});

export const createKey = (title, publicKey, privateKey) => {
  return async (dispatch) => {
    dispatch(createKeyRequest());
    try {
      const { data } = await Account.createKey(title, publicKey);
      localStorage.setItem(`private_${data.id}`, privateKey);
      dispatch(
        createKeySuccess({
          title,
          publicKey,
          privateKey,
          id: data.id,
          createdAt: data.createdAt,
        })
      );
    } catch (error) {
      dispatch(createKeyFailed(error));
    }
  };
};

export const deleteKeySuccess = () => ({
  type: 'DELETE_KEY_SUCCESS',
});

export const deleteKeyFailed = (error) => ({
  type: 'DELETE_KEY_FAILED',
  payload: error,
});

export const deleteKeyRequest = () => ({
  type: 'DELETE_KEY_REQUEST',
});

export const deleteKey = (id) => {
  return async (dispatch) => {
    dispatch(deleteKeyRequest());
    try {
      await Account.deleteKey(id);
      localStorage.removeItem(`private_${id}`);
      dispatch(deleteKeySuccess());
    } catch (error) {
      dispatch(deleteKeyFailed(error));
    }
  };
};

export const reinitializeAccountState = () => ({
  type: 'REINITIALIZE_STATE',
});
