import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import inapp from "@/apis/inapp";

// material-ui components
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";

// core components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

//Dyujoy: Import useSelector
import { useSelector, useDispatch } from "react-redux";
import Step1 from "./step1";

import { getRooms, createRoom } from "@/actions";

const useStyles = makeStyles(styles);

function Vcr(props) {
  const classes = useStyles();
  const [recipients, setRecipients] = useState([]);
  const [newRoomName, setNewRoomName] = useState("");
  const dispatch = useDispatch();

  const [selectedKeyName, setSelectedKeyName] = useState("");
  const [selectedKey, setSelectedKey] = useState("");

  const loggedIn = useSelector((state) => state.login.loggedIn);

  // Check to see if the user is logged in
  useEffect(() => {
    // addNotification('hi there');
    if (!loggedIn) {
      props.history.push("/auth/login-page");
    }
    dispatch(getRooms());
  }, [loggedIn, props.history, dispatch]);

  const handleEmailInvite = async () => {
    await inapp.post("/v1/vcr/sendInvite", { recipients });
  };

  // Email and name label
  const [emailLabel, setEmailLabel] = useState("");
  const [nameLabel, setNameLabel] = useState("");

  // Email and name error
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");

  // Check if name and email is filled or not

  // To check whether name and email creates an error
  let error = false;
  const nameAndEmailCheck = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!newRoomName) {
      error = true;
      setNameLabel("Please fill the name of the room");
      setNameError("error");
    } else {
      setNameLabel("");
      setNameError("");
    }
    if (!recipients || !pattern.test(recipients)) {
      error = true;
      setEmailLabel("Please fill the appropriate email");
      setEmailError("error");
    } else {
      setEmailLabel("");
      setEmailError("");
    }

    if (!selectedKey) {
      error = true;
    }

    if (error === true) {
      return;
    }
    handleEmailInvite();
    createRoom_demo(newRoomName);
    setNewRoomName("");
    setRecipients("");
    setSelectedKeyName("");
    error = false;
  };

  // To get the user's ID from redux store
  const userId = useSelector((state) => state.profile.me.user_id);

  const rooms = useSelector((state) => state.vcr.rooms);

  const history = props.history;

  const showRoom = () => {
    if (!rooms) {
      return [];
    }

    let row = [];
    for (let i = 0; i < rooms.length; i = i + 1) {
      row.push([
        <span>{i + 1}.</span>,

        <Link to={`/admin/vcrs/${rooms[i].room_id}`} style={{ fontSize: 15 }}>
          {rooms[i].vcr_Room.name}
        </Link>,
        <span style={{ fontSize: 15 }}>{rooms[i].createdAt}</span>,
        <span style={{ fontSize: 15 }}>{rooms[i].type}</span>,
        <span>
          {rooms[i].type === "Owner" && (
            <DeleteIcon
              color="secondary"
              disabled={rooms[i].type !== "Owner"}
              onClick={async () => {
                await handleDeleteNew(rooms[i].room_id);
              }}
            />
          )}
        </span>,
      ]);
    }
    return row;
  };

  //Dyujoy:  Delete Room from the list
  const handleDeleteNew = async (roomId) => {
    await inapp.delete("/v1/vcr/deleteRooms", {
      data: { room_id: roomId },
    });

    dispatch(getRooms());
    // setSelectedRoom([]);
  };

  // Connect to create room backend

  const createRoom_demo = async (name) => {
    dispatch(createRoom(name, selectedKey, recipients, userId));
  };

  // Get keys from redux store
  const userKeys = useSelector((state) => state.keys.list);
  // Get the selected key

  // Returns the keys in the dropdown option
  const currentUserKeys = userKeys.map((key) => key.title);

  const showKeys = () => {
    return (
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value="2"
      >
        Paris
      </MenuItem>
    );
  };

  const checkKeyId = (name) => {
    const keyId = userKeys.filter((key) => key.title === name)[0]?.id;

    setSelectedKeyName(name);
    setSelectedKey(keyId);
  };

  const showVcrWizard = () => {
    return (
      <div>
        <Step1
          classes={classes}
          setNewRoomName={setNewRoomName}
          newRoomName={newRoomName}
          nameLabel={nameLabel}
          setNameLabel={setNameLabel}
          nameError={nameError}
          setNameError={setNameError}
          setRecipients={setRecipients}
          recipients={recipients}
          emailLabel={emailLabel}
          setEmailLabel={setEmailLabel}
          setEmailError={setEmailError}
          emailError={emailError}
          selectedKey={selectedKey}
          setSelectedKey={setSelectedKey}
          userKeys={userKeys}
          checkKeyId={checkKeyId}
          currentUserKeys={currentUserKeys}
          nameAndEmailCheck={nameAndEmailCheck}
          createRoom_demo={createRoom_demo}
          handleEmailInvite={handleEmailInvite}
          showKeys={showKeys}
          setSelectedKeyName={setSelectedKeyName}
          selectedKeyName={selectedKeyName}
          userId={userId}
          history={history}
        />
      </div>
    );
  };

  const showNavPills = () => {
    return (
      <NavPills
        alignCenter
        tabs={[
          {
            tabButton: "Available Rooms",
            tabContent: (
              <div>
                <Card>
                  <CardBody>
                    <h3>Available Rooms:</h3>
                    <div>
                      <Table
                        tableHead={["", "Name", "Created At", "Type", ""]}
                        tableData={showRoom()}
                      />
                    </div>
                  </CardBody>
                </Card>
              </div>
            ),
          },
          {
            tabButton: "Create room",
            tabContent: showVcrWizard(),
          },
        ]}
      />
    );
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={8}></GridItem>
        <GridItem xs={12} sm={12}>
          {showNavPills()}
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withRouter(Vcr);
