import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";

const useStyles = makeStyles(styles);

export default function PricingPage() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>Pick the best plan for you</h2>
          {/* <h5 className={classes.description}>
            You have Free Unlimited Updates and Premium Support on each package.
          </h5> */}
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing raised>
              <h6 className={classes.cardCategory}>Trial</h6>
              <div className={classes.icon}>
                <Icon className={classes.iconRose}>weekend</Icon>
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                FREE
              </h3>
              <p className={classes.cardDescription}>You get 5 IPT Tokens</p>
              <Button
                round
                color="rose"
                onClick={useCallback(
                  () => history.push("/admin/checkout-page/5credits/usd"),
                  [history]
                )}
              >
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card id="card2" pricing raised>
            <CardBody pricing raised>
              <h6 className={classes.cardCategory}>STARTER PLAN</h6>
              <div className={classes.icon}>
                <Home className={classes.iconRose} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                $10
              </h3>
              <p className={classes.cardDescription}>You get 10 IPT Tokens</p>
              <Button
                round
                color="rose"
                onClick={useCallback(
                  () => history.push("/admin/checkout-page/10credits/usd"),
                  [history]
                )}
              >
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing raised>
              <h6 className={classes.cardCategory}>BULK DISCOUNT PLAN 1</h6>
              <div className={classes.icon}>
                <Business className={classes.iconRose} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                $50
              </h3>
              <p className={classes.cardDescription}>You get 55 IPT Tokens</p>
              <Button
                round
                color="rose"
                onClick={useCallback(
                  () => history.push("/admin/checkout-page/55credits/usd"),
                  [history]
                )}
              >
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing raised>
              <h6 className={classes.cardCategory}>BULK DISCOUNT PLAN 2</h6>
              <div className={classes.icon}>
                <AccountBalance className={classes.iconRose} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                $100
              </h3>
              <p className={classes.cardDescription}>You get 120 IPT Tokens</p>
              <Button
                round
                color="rose"
                onClick={useCallback(
                  () => history.push("/admin/checkout-page/120credits/usd"),
                  [history]
                )}
              >
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
