import { combineReducers } from 'redux';
import AccountReducer from './account.reducer';
import loginReducer from './Login.reducer';
import profileReducer from './Profile.reducer';
import signupReducer from './SignUp.reducer';
import keysReducer from './Keys.reducer';
import VCRReducer from './VCR.reducer';

export default combineReducers({
  account: AccountReducer,
  login: loginReducer,
  profile: profileReducer,
  signup: signupReducer,
  keys: keysReducer,
  vcr: VCRReducer
});
