import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

class CreateSurveyWizardStep3 extends React.Component
{
  /* 发送结果 */
  sendState() {
    return this.state;
  }

  /* 是否允许下一步 */
  isValidated() {
    return true;
  }

  render() {
    return (
      <div style={{paddingLeft:30}}>
        <h1>Preview</h1>
      </div>
    )
  }
}

export default withStyles({})(CreateSurveyWizardStep3);
