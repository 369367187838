import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _sodium from "libsodium-wrappers";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import combineStyles from "@/combineStyles";

// @material-ui/icons
import Delete from "@material-ui/icons/Delete";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import NavPills from "components/NavPills/NavPills.js";

// import CardHeader from "components/mui/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";

import { Account } from "@/apis";

import {
  changePassword,
  updateUser,
  uploadAvatar,
  fetchUserProfile,
  createKey,
  getKeys,
  deleteKey,
  checkLogin,
} from "@/actions";

class SettingsPage extends React.Component {
  state = {
    avatar: this.props.profile.me.avatar,
    avatarState: "",
    selectedFile: null,
    nickname: this.props.profile.me.nickname,
    nicknameState: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    confirmPasswordState: "",
    title: "",
    titleState: "",
    publicKey: "",
    publicKeyState: "",
    privateKey: "",
    privateKeyState: "",
    publicKeyFileName: "publicKey.txt",
    publicKeyFilenameState: "",
    privateKeyFileName: "privateKey.txt",
    privateKeyFilenameState: "",
    saveProfileHasError: false,
    sweetAlert: null,
  };

  componentDidMount() {
    // check login
    this.props.checkLogin();
    if (!this.props.login.loggedIn) {
      this.props.history.push("/auth/login-page");
    }
  }

  componentDidUpdate(prevProps) {
    const { classes, profile } = this.props;
    if (profile.changePassError || profile.updateUserFailure) {
      this.setState({
        sweetAlert: (
          <SweetAlert
            danger
            style={{ display: "block" }}
            title="Save Profile Failed"
            onConfirm={() => this._hideAlert()}
            confirmBtnCssClass={classes.button + " " + classes.danger}
          />
        ),
      });
    }
  }

  /* 渲染 profile 编辑 */
  _renderProfile() {
    const { classes } = this.props;
    const {
      avatar,
      nickname,
      nicknameState,
      oldPassword,
      newPassword,
      confirmPassword,
      confirmPasswordState,
    } = this.state;

    return (
      <div>
        <Button
          color="primary"
          style={{ marginBottom: 50 }}
          onClick={() => this._addIPT()}
        >
          Add IPT Tokens to your Metamask
        </Button>
        <div></div>
        <div></div>

        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitleWhite}>Profile</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <input
              accept="image/*"
              id="icon-button-file"
              type="file"
              style={{ display: "none" }}
              onChange={(e) =>
                this.setState({ selectedFile: e.target.files[0] })
              }
            />
            <label htmlFor="icon-button-file">
              <h6>Click on Avatar to change image</h6>
              <IconButton component="span">
                <Avatar src={avatar} className={classes.orange}>
                  {nickname.length > 0 ? nickname.charAt(0) : ""}
                </Avatar>
              </IconButton>
            </label>
            <CustomInput
              error={nicknameState === "error"}
              formControlProps={{ fullWidth: true }}
              labelText="Nickname (required)"
              inputProps={{
                type: "text",
                value: nickname,
                onChange: (e) => {
                  this.setState({ nickname: e.target.value });
                },
              }}
            />
            <CustomInput
              formControlProps={{ fullWidth: true }}
              labelText="Old Password"
              inputProps={{
                type: "password",
                value: oldPassword,
                onChange: (e) => {
                  this.setState({ oldPassword: e.target.value });
                },
              }}
            />
            <CustomInput
              formControlProps={{ fullWidth: true }}
              labelText="New Password"
              inputProps={{
                type: "password",
                value: newPassword,
                onChange: (e) => {
                  this.setState({ newPassword: e.target.value });
                },
              }}
            />
            <CustomInput
              error={confirmPasswordState === "error"}
              formControlProps={{ fullWidth: true }}
              labelText="Confirm New Password"
              inputProps={{
                type: "password",
                value: confirmPassword,
                onChange: (e) => {
                  this.setState({ confirmPassword: e.target.value });
                },
              }}
            />
            <Button color="primary" onClick={() => this._saveProfile()}>
              Save
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }

  async _addIPT() {
    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
    const tokenSymbol = "IPT";
    const tokenDecimals = 0;
    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
          },
        },
      });

      if (wasAdded) {
        console.log("Coin added");
      } else {
        console.error("Coin not added");
      }
    } catch (error) {
      console.error(error);
    }
  }

  /* 保存 profile */
  async _saveProfile() {
    const { selectedFile } = this.state;

    this.setState({
      saveProfileHasError: false,
      nicknameState: "",
      confirmPasswordState: "",
    });

    if (selectedFile) {
      const data = new FormData();
      data.append("profileImage", selectedFile);
      await this.props.uploadAvatar(data);
      this.setState({ selectedFile: null });
    }
  }

  /* 渲染 keys 列表 */
  _renderRequestKeys() {
    const { classes } = this.props;
    const { list } = this.props.keys;

    return (
      <div>
        <div style={{ marginBottom: 125 }}></div>

        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitleWhite}>
                Available Keys
                {list && list.length > 0 ? (
                  <small> ( {list.length} )</small>
                ) : null}
              </h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={["Title", "Created", "Action"]}
              tableData={this._renderRequestsTableData()}
            />
          </CardBody>
        </Card>
      </div>
    );
  }

  /* render keys table data */
  _renderRequestsTableData() {
    const { list } = this.props.keys;

    if (!list) {
      return [];
    }

    let rows = [];
    for (let i = 0, len = list.length; i < len; ++i) {
      const key = list[i];

      rows.push([
        <div>
          <h4>{key.title}</h4>
          <span>{key.id.match(/\w{2}/g).join(":")}</span>
        </div>,
        <span>{key.createdAt}</span>,
        <div>
          <Button
            color="facebook"
            size="sm"
            simple
            onClick={() => this._deleteKey(key)}
          >
            <Delete /> Delete
          </Button>
        </div>,
      ]);
    }
    return rows;
  }

  /* render add key */
  _renderAddKey() {
    const { title, titleState, publicKeyState, privateKeyState } = this.state;

    return (
      <Card>
        <CardBody>
          <CustomInput
            error={titleState === "error"}
            formControlProps={{ fullWidth: true }}
            labelText="Title (required)"
            inputProps={{
              type: "text",
              value: title,
              onChange: (e) => {
                this.setState({ title: e.target.value });
              },
            }}
          />
          <label>Select Public Key</label>
          <CustomInput
            error={publicKeyState === "error"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "file",
              accept: ".pem",
              onChange: (e) => {
                if (e.target.files && e.target.files[0]) {
                  // 读取 public key 内容到 state
                  let fileReader = new FileReader();
                  fileReader.onloadend = () => {
                    this.setState({ publicKey: fileReader.result });
                  };
                  fileReader.readAsBinaryString(e.target.files[0]);
                }
              },
            }}
          />
          <label>Select Private Key</label>
          <CustomInput
            error={privateKeyState === "error"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "file",
              accept: ".pem",
              onChange: (e) => {
                if (e.target.files && e.target.files[0]) {
                  // 读取 private key 内容到 state
                  let fileReader = new FileReader();
                  fileReader.onloadend = () => {
                    this.setState({ privateKey: fileReader.result });
                  };
                  fileReader.readAsBinaryString(e.target.files[0]);
                }
              },
            }}
          />
          <Button color="primary" onClick={() => this._saveKey()}>
            Load
          </Button>
        </CardBody>
      </Card>
    );
  }

  /* 保存 key */
  _saveKey = async () => {
    const { title, publicKey, privateKey } = this.state;
    let hasError = false;

    this.setState({
      titleState: "",
      publicKeyState: "",
      privateKeyState: "",
    });

    if (title.trim() === "") {
      hasError = true;
      this.setState({ titleState: "error" });
    }
    if (publicKey.trim() === "") {
      hasError = true;
      this.setState({ publicKeyState: "error" });
    }
    if (privateKey.trim() === "") {
      hasError = true;
      this.setState({ privateKeyState: "error" });
    }
    if (hasError) {
      return false;
    }

    await this.props.createKey(
      title.trim(),
      publicKey.trim(),
      privateKey.trim()
    );

    await this.props.getKeys();
  };

  /* delete */
  _deleteKey = (key) => {
    this.setState({
      sweetAlert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={async () => {
            await this.props.deleteKey(key.id);
            await this.props.getKeys();
            this.setState({
              sweetAlert: (
                <SweetAlert
                  success
                  style={{ display: "block" }}
                  title="Deleted!"
                  onConfirm={() => this._hideAlert()}
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                />
              ),
            });
          }}
          onCancel={() => this._hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        />
      ),
    });
  };

  _generateKeyPair = async () => {
    await _sodium.ready;
    const sodium = _sodium;

    const keyPair = await Account.generateKeyPair();
    const { publicKey, privateKey } = keyPair;
    this.setState({
      publicKey: sodium.to_hex(publicKey),
      privateKey: sodium.to_hex(privateKey),
    });
    this._saveKey();
  };

  _renderCreateKey() {
    const { title, titleState } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <CustomInput
              error={titleState === "error"}
              formControlProps={{ fullWidth: true }}
              labelText="Title (required)"
              inputProps={{
                type: "text",
                value: title,
                onChange: (e) => {
                  this.setState({ title: e.target.value });
                },
              }}
            />
            <Button color="primary" onClick={() => this._generateKeyPair()}>
              Generate
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }

  downloadKeyFile = (filename, id) => {
    const element = document.createElement("a");
    const file = new Blob([this.state[id]], {
      type: ".pem",
    });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  _hideAlert = () => {
    this.setState({ sweetAlert: null });
  };

  render() {
    const { login } = this.props;
    const {
      publicKey,
      privateKey,
      publicKeyFileName,
      publicKeyFilenameState,
      privateKeyFileName,
      privateKeyFilenameState,
      sweetAlert,
    } = this.state;

    return login.loggedIn ? (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            {this._renderProfile()}
          </GridItem>
          <GridItem xs={12} sm={8}>
            <GridItem>{this._renderRequestKeys()}</GridItem>
            <GridItem>
              <Card>
                <CardBody>
                  <h6>Instructions</h6>
                  <p>"GENERATE KEY PAIR" will generate a new public and private key pair.  If you already have a key pair, click on "LOAD KEY PAIR"</p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem>
              <NavPills
                color="warning"
                tabs={[
                  {
                    tabButton: "Generate Key Pair",
                    tabContent: this._renderCreateKey(),
                  },
                  {
                    tabButton: "Load Key Pair",
                    tabContent: this._renderAddKey(),
                  },
                ]}
              />
            </GridItem>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {publicKey ? (
            <GridItem>
              <Card>
                <CardBody>
                  <p>{this.state.publicKey}</p>
                  <CustomInput
                    error={publicKeyFilenameState === "error"}
                    formControlProps={{ fullWidth: true }}
                    labelText="File Name"
                    inputProps={{
                      type: "text",
                      value: publicKeyFileName,
                      onChange: (e) => {
                        this.setState({ publicKeyFileName: e.target.value });
                      },
                    }}
                  />
                  <Button
                    color="primary"
                    onClick={() =>
                      this.downloadKeyFile(publicKeyFileName, "publicKey")
                    }
                  >
                    Download
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          ) : (
            ""
          )}
          {privateKey ? (
            <GridItem>
              <Card>
                <CardBody>
                  <p>{this.state.privateKey}</p>
                  <CustomInput
                    error={privateKeyFilenameState === "error"}
                    formControlProps={{ fullWidth: true }}
                    labelText="File Name"
                    inputProps={{
                      type: "text",
                      value: privateKeyFileName,
                      onChange: (e) => {
                        this.setState({ privateKeyFileName: e.target.value });
                      },
                    }}
                  />
                  <Button
                    color="primary"
                    onClick={() =>
                      this.downloadKeyFile(privateKeyFileName, "privateKey")
                    }
                  >
                    Download
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          ) : (
            ""
          )}
        </GridContainer>
        {sweetAlert}
      </div>
    ) : null;
  }
}

SettingsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  login: state.login,
  profile: state.profile,
  keys: state.keys,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (oldPassword, newPassword) =>
    dispatch(changePassword(oldPassword, newPassword)),
  updateUser: (update) => dispatch(updateUser(update)),
  uploadAvatar: (data) => dispatch(uploadAvatar(data)),
  fetchUserProfile: () => dispatch(fetchUserProfile()),
  getKeys: () => dispatch(getKeys()),
  createKey: (title, publicKey, privateKey) =>
    dispatch(createKey(title, publicKey, privateKey)),
  deleteKey: (id) => dispatch(deleteKey(id)),
  checkLogin: () => dispatch(checkLogin()),
});

const connectedSettingsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SettingsPage));

export default withStyles(
  combineStyles(dashboardStyle, regularFormsStyle, sweetAlertStyle)
)(connectedSettingsPage);
