import Dashboard from "views/Dashboard/Dashboard";
import CreateDataAccessWizard from "views/DataAccessRequest/CreateDataAccessWizard";
import CreateSurveyWizard from "views/DataAccessRequest/CreateSurveyWizard";
import ListRequests from "views/DataAccessRequest/ListRequests";
import LoginPage from "views/Pages/LoginPage";
import LogoutPage from "views/Pages/LogoutPage";
import RegisterPage from "views/Pages/RegisterPage";
import SettingsPage from "views/Pages/SettingsPage";
import ErrorPage from "views/Pages/ErrorPage.js";
import LockScreenPage from "views/Pages/LockScreenPage";
import Vcr from "views/VirtualCleanRoom/Vcr";
import RoomWiget from "views/VirtualCleanRoom/RoomWidgetLocalEncrypt";
import PricingPage from "views/Pages/PricingPage";
import CheckoutPage from "views/Pages/CheckoutPage";
import CollectionDetail from "views/DataAccessRequest/CollectionDetail";
import SurveyDetail from "views/DataAccessRequest/SurveyDetail";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import VCRIcon from "@material-ui/icons/Archive";

const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  // {
  //   collapse: false,
  //   name: "Data Access Request",
  //   icon: ReportIcon,
  //   state: "pageCollapse",
  //   views: [
  {
    path: "/request/collection/new",
    name: "Create Data Access Request",
    component: CreateDataAccessWizard,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/request/survey/new",
    name: "Create Survey Request",
    component: CreateSurveyWizard,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/request/collection/list",
    name: "Collections",
    component: ListRequests,
    layout: "/admin",
    type: "collection",
    invisible: true,
  },
  {
    path: "/request/collection/detail/:id",
    name: "Collection Detail",
    icon: DashboardIcon,
    component: CollectionDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/request/survey/detail/:id",
    name: "Survey Detail",
    icon: DashboardIcon,
    component: SurveyDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/request/survey/list",
    name: "Surveys",
    component: ListRequests,
    layout: "/admin",
    type: "survey",
    invisible: true,
  },

  {
    path: "/vcr",
    name: "Virtual Clean Room",
    icon: VCRIcon,
    component: Vcr,
    layout: "/admin",
  },

  {
    path: "/pricing-page",
    name: "Purchase IPT Tokens",
    mini: "PP",
    component: PricingPage,
    layout: "/auth",
  },
  {
    path: "/vcrs/:id",
    name: "Virtual Clean Room Page",
    icon: VCRIcon,
    component: RoomWiget,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/checkout-page/:item/:currency",
    name: "Checkout Page",
    mini: "CP",
    component: CheckoutPage,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: SettingsPage,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/login-page",
    name: "Login Page",
    component: LoginPage,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/logout-page",
    name: "Logout Page",
    component: LogoutPage,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/error-page",
    name: "Error Page",
    component: ErrorPage,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/lock-screen-page",
    name: "Lock Screen Page",
    component: LockScreenPage,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/register-page",
    name: "Register Page",
    component: RegisterPage,
    layout: "/auth",
    invisible: true,
  },
];

export default dashRoutes;
