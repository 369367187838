import axios from 'axios';
import qs from 'qs';
import store from '@/store';

const inst = axios.create({
  baseURL: process.env.REACT_APP_PHPAPI,
  timeout: 30000,
});

const funcBuildUrl = (url, query) => {
  return url + (url.indexOf('?') === -1 ? '?' : '&') + qs.stringify(query);
};

async function _requestRaw(method, url, query, params) {
  const { account } = store.getState();
  let result;
  if (account.id) {  // 设置用户会话信息
    if (query) {
      query.va = account.id;
    } else {
      query = { va: account.id };
    }
  }
  if (method === 'GET') {
    result = await inst.get(funcBuildUrl(url, query));
  } else if (method === 'POST') {
    result = await inst.post(funcBuildUrl(url, query), params);
  } else {
    throw new Error('Request operate error');
  }
  if (result.status !== 200 || !result.data) {
    throw new Error('Request is invalid');
  }
  return result.data;
};

async function _request(method, url, query, params) {
  const data = await _requestRaw(method, url, query, params);
  if (data.code !== 0) {
    throw new Error(data.msg || 'Request data error');
  }
  return data.result;
}

export default {
  getRequest() {
    return inst;
  },
  async get(url, query) {
    return await _request('GET', url, query);
  },
  async getRaw(url, query) {
    return await _requestRaw('GET', url, query);
  },
  async post(url, query, params) {
    return await _request('POST', url, query, params);
  },
  async postRaw(url, query, params) {
    return await _requestRaw('POST', url, query, params);
  }
}
