import { Account } from '@/apis';
import { fetchUserProfile } from './Profile.actions';
import { getKeys } from './Key.actions';

export const logoutSuccess = () => ({
  type: 'LOGOUT_SUCCESS',
});

export const loginSuccess = () => ({
  type: 'LOGIN_SUCCESS',
});

export const loginFailed = (message) => ({
  type: 'LOGIN_FAILED',
  payload: message,
});

export const loginRequest = () => ({
  type: 'LOGIN_REQUEST',
});

export const login = (loginData) => {
  return async (dispatch) => {
    dispatch(loginRequest());

    await Account.login(loginData)
      .then(({ data }) => {
        dispatch(loginSuccess(data.account));
        dispatch(fetchUserProfile());
        dispatch(getKeys());
      })
      .catch((e) => {
        dispatch(loginFailed(e));
      });
  };
};

export const logout = () => {
  return async (dispatch) => {
    await Account.logout().then(() => {
      dispatch(logoutSuccess());
    });
  };
};

export const checkLogin = () => {
  return async (dispatch) => {
    try {
      await Account.status();
    } catch (error) {
      dispatch(logout());
    }
  };
};

export const reinitializeLoginState = () => ({
  type: 'REINITIALIZE_STATE',
});
