export default {
  'Agree - Disagree': [ 'Strongly agree', 'Agree', 'Neither agree nor disagree', 'Disagree', 'Strongly disagree' ],
  'Satisfied - Dissatisfied': [ 'Very satisfied', 'Satisfied', 'Neither satisfied nor dissatisfied', 'Dissatisfied', 'Very dissatisfied' ],
  'Yes - No': [ 'Yes', 'No' ],
  'Likely - Unlikely': [ 'Very likely', 'Likely', 'Neither likely nor unlikely', 'Unlikely', 'Very unlikely' ],
  'Familiar - Not familiar': [ 'Extremely familiar', 'Very familiar', 'Somewhat familiar', 'Not so familiar', 'Not at all familiar' ],
  'A great deal - None at all': [ 'A great deal', 'A lot', 'A moderate amount', 'A little', 'None at all' ],
  'Interested - Not interested': [ 'Extremely interested', 'Very interested', 'Somewhat interested', 'Not so interested', 'Not at all interested' ],
  'Easy - Difficult': [ 'Very easy', 'Easy', 'Neither easy nor difficult', 'Difficult', 'Very difficult' ],
  'Always - Never': [ 'Always', 'Usually', 'Sometimes', 'Rarely', 'Never' ],
  'Better - Worse': [ 'Much better', 'Better', 'About the same', 'Worse', 'Much worse' ],
  'Approve - Disapprove': [ 'Strongly approve', 'Approve', 'Neither approve nor disapprove', 'Disapprove', 'Strongly disapprove' ],
  'Above average - Below average': [ 'Far above average', 'Above average', 'Average', 'Below average', 'Far below average' ],
  'High quality - Low quality': [ 'Very high quality', 'High quality', 'Neither high nor low quality', 'Low quality', 'Very low quality' ],
  'True - False': [ 'True', 'False' ],
  'Definitely would - Definitely would not': [ 'Definitely would', 'Probably would', 'Probably would not', 'Definitely would not' ],
  'Useful - Not useful': [ 'Extremely useful', 'Very useful', 'Somewhat useful', 'Not so useful', 'Not at all useful' ],
  'Valuable - Not valuable': [ 'Extremely valuable', 'Very valuable', 'Somewhat valuable', 'Not so valuable', 'Not at all valuable' ],
  'Clear - Not clear': [ 'Extremely clear', 'Very clear', 'Somewhat clear', 'Not so clear', 'Not at all clear' ],
  'Helpful - Not helpful': [ 'Extremely helpful', 'Very helpful', 'Somewhat helpful', 'Not so helpful', 'Not at all helpful' ],
  'All - None': [ 'All', 'Most', 'Some', 'A few', 'None' ],
  'Friendly - Not friendly': [ 'Extremely friendly', 'Very friendly', 'Somewhat friendly', 'Not so friendly', 'Not at all friendly' ],
  'Effective - Not effective': [ 'Extremely effective', 'Very effective', 'Somewhat effective', 'Not so effective', 'Not at all effective' ],
  'Positive - Negative': [ 'Very positive', 'Positive', 'Neutral', 'Negative', 'Very negative' ],
  'Too short - Too long': [ 'Much too short', 'Too short', 'About the right length', 'Too long', 'Much too long' ],
  'Responsive - Not responsive': [ 'Extremely responsive', 'Very responsive', 'Somewhat responsive', 'Not so responsive', 'Not at all responsive' ],
  'Top priority - Not a priority': [ 'The most important priority', 'A top priority, but not the most important', 'Not very important', 'Not important at all' ],
  'Important - Not important': [ 'Extremely important', 'Very important', 'Somewhat important', 'Not so important', 'Not at all important' ],
  'Aware - Not aware': [ 'Extremely aware', 'Very aware', 'Somewhat aware', 'Not so aware', 'Not at all aware' ],
  'Desirable - Not desirable': [ 'Extremely desirable', 'Very desirable', 'Somewhat desirable', 'Not so desirable', 'Not at all desirable' ],
  'Confident - Not confident': [ 'Extremely confident', 'Very confident', 'Somewhat confident', 'Not so confident', 'Not at all confident' ],
  'Professional - Not professional': [ 'Extremely professional', 'Very professional', 'Somewhat professional', 'Not so professional', 'Not at all professional' ],
  'Clearly - Not clearly': [ 'Extremely clearly', 'Very clearly', 'Somewhat clearly', 'Not so clearly', 'Not at all clearly' ],
  'Exceeded expectations - Below expectations': [ 'Exceeded expectations', 'Met expectations', 'Below expectations' ],
  'Attentive - Not attentive': [ 'Extremely attentive', 'Very attentive', 'Somewhat attentive', 'Not so attentive', 'Not at all attentive' ],
  'Early - Late': [ 'Early', 'On time', 'Late' ],
  'Months': [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
  'Days of the week': [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ],
  'Income': [ 'Under $15,000', 'Between $15,000 and $29,999', 'Between $30,000 and $49,999', 'Between $50,000 and $74,999', 'Between $75,000 and $99,999', 'Between $100,000 and $150,000', 'Over $150,000' ],
  'Age': [ 'Under 18', '18-24', '25-34', '35-44', '45-54', '55-64', '65+' ],
  'Quarters': [ 'Monthly', 'Twice annually', 'Quarterly', 'Annually' ],
  'Frequency': [ 'Every day', 'A few times a week', 'About once a week', 'A few times a month', 'Once a month', 'Less than once a month' ],
}
