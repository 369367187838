import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import inapp from '@/apis/inapp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Notifications from '@material-ui/icons/Notifications';
import Dashboard from '@material-ui/icons/Dashboard';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';

// core components
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';
import { ethers } from 'ethers';
import '@ethersproject/shims';
import detectEthereumProvider from '@metamask/detect-provider';

import { PROVIDER } from 'config/settings';
import { tokenAbi } from 'config/abi';

const { Contract } = require('ethers');
const useStyles = makeStyles(styles);

async function requestAccount() {
  await window.ethereum.request({ method: 'eth_requestAccounts' });
}

export default function HeaderLinks(props) {
  const loggedIn = useSelector((state) => state.login.loggedIn);
  const [openCredit, setOpenCredit] = React.useState(null);
  const handleClickCredit = async (event) => {
    if (openCredit && openCredit.contains(event.target)) {
      setOpenCredit(null);
    } else {
      setOpenCredit(event.currentTarget);
      const get_token = await getERC20();

      setERC20(get_token);
    }
  };

  const handleCloseCredit = async () => {
    setOpenCredit(null);
  };

  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [ERC20, setERC20] = useState('Loading:  ');

  const getERC20 = async () => {
    const check_metamask = await detectEthereumProvider();
    var signer_address;
    if (check_metamask) {
      await requestAccount();

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      signer_address = await signer.getAddress();
    }

    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;

    const contract = new Contract(tokenAddress, tokenAbi, PROVIDER);
    const balance = await contract.balanceOf(signer_address);
    return balance.toString();
  };

  const getNotifications = async () => {
    const result = await inapp.get('/v1/auth/user/getNotifications');
    setNotificationCount(result.data.payload.length);
    setNotifications(result.data.payload);
  };
  useEffect(() => {
    let mounted = true;

    async function asyncGetNotification() {
      await getNotifications();
      // const get_token = await getERC20();
      // setERC20(get_token);
    }

    if (mounted && loggedIn) {
      asyncGetNotification();
    }

    return () => {
      mounted = false;
    };
  }, [loggedIn]);

  const [openNotification, setOpenNotification] = React.useState(null);

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const classes = useStyles();
  const { rtlActive } = props;

  const searchButton =
    classes.top +
    ' ' +
    classes.searchButton +
    ' ' +
    classNames({
      [classes.searchRTL]: rtlActive,
    });

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });

  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });

  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const handleDeleteNotification = async (id) => {
    await inapp.delete('/v1/auth/user/deleteNotification', { data: { id } });
    await getNotifications();
  };

  const displayNotifications = notifications.map((item) => {
    return (
      <MenuItem
        key={item.id}
        onClick={() => {
          handleDeleteNotification(item.id);
          handleCloseNotification();
        }}
        className={dropdownItem}
      >
        {item.notification}
        <Tooltip id='tooltip-top-start' title='Remove' placement='top'>
          <IconButton aria-label='Close' className={classes.buttonIcon}>
            <Close
              className={classes.menuActionButtonIcon + ' ' + classes.close}
            />
          </IconButton>
        </Tooltip>
      </MenuItem>
    );
  });

  return (
    <div className={wrapper}>
      <CustomInput
        rtlActive={rtlActive}
        formControlProps={{
          className: classes.top + ' ' + classes.search,
        }}
        inputProps={{
          placeholder: rtlActive ? 'بحث' : 'Search',
          inputProps: {
            'aria-label': rtlActive ? 'بحث' : 'Search',
            className: classes.searchInput,
          },
        }}
      />
      <Button
        color='white'
        aria-label='edit'
        justIcon
        round
        className={searchButton}
      >
        <Search className={classes.headerLinksSvg + ' ' + classes.searchIcon} />
      </Button>
      <Button
        color='transparent'
        onClick={() => props.history.push('/admin/dashboard')}
        simple
        aria-label='Dashboard'
        justIcon
        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
        muiClasses={{
          label: rtlActive ? classes.labelRTL : '',
        }}
      >
        <Dashboard
          className={
            classes.headerLinksSvg +
            ' ' +
            (rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links)
          }
        />
        <Hidden mdUp implementation='css'>
          <span className={classes.linkText}>
            {rtlActive ? 'لوحة القيادة' : 'Dashboard'}
          </span>
        </Hidden>
      </Button>
      <div className={managerClasses}>
        <Button
          color='transparent'
          justIcon
          aria-label='Notifications'
          aria-owns={openNotification ? 'notification-menu-list' : null}
          aria-haspopup='true'
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <Notifications
            className={
              classes.headerLinksSvg +
              ' ' +
              (rtlActive
                ? classes.links + ' ' + classes.linksRTL
                : classes.links)
            }
          />
          {notificationCount === 0 ? (
            ''
          ) : (
            <span className={classes.notifications}>{notificationCount}</span>
          )}
          <Hidden mdUp implementation='css'>
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {rtlActive ? 'إعلام' : 'Notification'}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement='bottom'
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id='notification-menu-list'
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role='menu'>{displayNotifications}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={managerClasses}>
        <Button
          color='transparent'
          justIcon
          aria-label='Credit'
          aria-owns={openCredit ? 'credit-menu-list' : null}
          aria-haspopup='true'
          onClick={handleClickCredit}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <MonetizationOnIcon
            className={
              classes.headerLinksSvg +
              ' ' +
              (rtlActive
                ? classes.links + ' ' + classes.linksRTL
                : classes.links)
            }
          />
          {/* <span className={classes.notifications}>{ERC20}</span> */}
          <Hidden mdUp implementation='css'>
            <span onClick={handleClickCredit} className={classes.linkText}>
              {rtlActive ? 'إعلام' : 'Credits'}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openCredit)}
          anchorEl={openCredit}
          transition
          disablePortal
          placement='bottom'
          className={classNames({
            [classes.popperClose]: !openCredit,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id='credit-menu-list'
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseCredit}>
                  <MenuList
                    role='menu'
                    onClick={() => console.log('hfehheheeh')}
                  >
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {ERC20} IPT Tokens Bought!
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={managerClasses}>
        <Button
          color='transparent'
          aria-label='Person'
          justIcon
          aria-owns={openProfile ? 'profile-menu-list' : null}
          aria-haspopup='true'
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              ' ' +
              (rtlActive
                ? classes.links + ' ' + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation='css'>
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? 'الملف الشخصي' : 'Profile'}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement='bottom'
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id='profile-menu-list'
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role='menu'>
                    {/* <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : "Profile"}
                    </MenuItem> */}
                    <MenuItem
                      onClick={() => props.history.push('/admin/settings')}
                      className={dropdownItem}
                    >
                      {rtlActive ? 'الإعدادات' : 'Settings'}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={() => props.history.push('/auth/logout-page')}
                      className={dropdownItem}
                    >
                      {rtlActive ? 'الخروج' : 'Log out'}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
