import inapp from './inapp';

const VCR = {
  async createRoom(name) {
    return await inapp.post('v1/vcr/createRoom', {
      name,
    });
  },

  async getPublicKey(room_id, user_id) {
    const resp = await inapp.get('/v1/vcr/getPublicKey', {
      params: {
        room_id,
        user_id,
      },
    });

    return resp.data.response_for_guestpublickey[0].key;
  },

  async createAESKey(room_id, selectedKey) {
    const response = await inapp.post('/v1/vcr/createAesKey', {
      room_id: room_id,
      public_key_id: selectedKey,
    });
    return response;
  },

  async createRoomUsers(room_id, recipients, userId) {
    return await inapp.post('/v1/vcr/createRoomUsers', {
      recipients: recipients,
      user_id: userId,
      room_id: room_id,
    });
  },

  async getRooms() {
    return await inapp.get('v1/vcr/getRooms', {});
  },

  async updateAesKey(userId, room_id, selectedKey, encryptedSessionKey) {
    return await inapp.post('/v1/vcr/updateAesKey', {
      user_id: userId,
      room_id,
      public_key_id: selectedKey,
      encryptedSessionKey,
    });
  },

  async getUserType(room_id) {
    return await inapp.get('/v1/vcr/getUsertype', {
      params: {
        room_id,
      },
    });
  },

  async getDataSet(room_id) {
    const { data } = await inapp.get('/v1/vcr/getDataSets', {
      params: {
        room_id,
      },
    });

    return data.payload;
  },

  async deleteDataSet(room_id, dataset_id) {
    await inapp.delete('/v1/vcr/deleteDataSet', {
      data: { room_id, dataset_id },
    });
  },

  async getRoomKeysDetails(room_id) {
    const response = await inapp.get('/v1/vcr/getRoomKeysDetails', {
      params: {
        room_id,
      },
    });

    return response;
  },

  async uploadEncrypted(campaign, room_id, header, encrypted) {
    await inapp.post('/v1/vcr/uploadEncrypted', {
      campaign,
      room_id,
      header,
      encrypted,
    });
  },

  async updateSessionKey(userId, room_id, selectedKey, encryptedSessionKey) {
    return await inapp.post('/v1/vcr/updateOwnerSession', {
      user_id: userId,
      room_id,
      public_key_id: selectedKey,
      encryptedSessionKey,
    });
  },

  async getFilters(dataset_id) {
    const response = await inapp.get('/v1/vcr/getFilters', {
      params: {
        dataset_id,
      },
    });
    return response;
  },

  async getOwnerId(room_id) {
    const response = await inapp.get('/v1/vcr/getOwnerId', {
      params: {
        room_id,
      },
    });
    return response.data.vcrRooms[0].owner_id;
  },

  async addNotification(notification, owner_id) {
    return await inapp.post('/v1/auth/user/createNotification', {
      notification,
      to_id: owner_id,
    });
  },

  async addGuest(room_id, receivers) {
    await inapp.post('/v1/vcr/sendInvite', {
      recipients: receivers,
    });

    return await inapp.post('/v1/vcr/createRoomUsers', {
      room_id,
      recipients: receivers,
    });
  },

  async getDataSetColumns(room_id, dataset_id) {
    return await inapp.get('/v1/vcr/getDataSetColumns', {
      params: { room_id, dataset_id },
    });
  },

  async joinDatasets(room_id, dataset1_id, dataset2_id, sqlWhere) {
    return await inapp.get('/v1/vcr/joinDatasets', {
      params: {
        room_id,
        dataset1_id,
        dataset2_id,
        sqlWhere,
      },
    });
  },

  async getRoomUsersCount(room_id) {
    return await inapp.get('/v1/vcr/getRoomUsersCount', {
      params: {
        room_id,
      },
    });
  },

  async getReport(room_id, datasets_array) {
    return await inapp.get('/v1/vcr/getReport', {
      params: {
        room_id,
        datasets_array,
      },
    });
  },
};

export default VCR;
