import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import inapp from "@/apis/inapp";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from "@material-ui/icons/Delete";

// @material-ui/icons
// import Edit from '@material-ui/icons/Edit';

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import { checkLogin } from "@/actions";
import { Request } from "@/apis";

//import { getList, createList } from '@/actions';

class Dashboard extends React.Component {
  _isMount = false;
  count = 1;
  contract_count = 1;

  state = {
    overviewData: null,
    requestsData: null,
    contractsData: null,
    // showCollection: 'none',
  };

  componentDidMount() {
    // check login
    this._isMount = true;
    // this.count = 1;

    this.props.checkLogin();
    if (!this.props.login.loggedIn) {
      this.props.history.push("/auth/login-page");
    }
    this._getRequestOverview();
    this._getRequestList();
    this._getcontractsList();
  }

  componentWillUnmount() {
    this._isMount = false;
    // this.count = 1;
  }

  async handleDeleteRequest(request) {
    await inapp.delete("/v1/requestClient/deleteRequest", {
      data: { request_id: request.id },
    });
    // render elements again to show changes
    this.componentDidMount();
  }

  /* 获取 request 今日概览 */
  _getRequestOverview() {
    Request.getOverview()
      // RequestApi.getOverview()
      .then(({ data }) => {
        if (this._isMount) {
          this.setState({ overviewData: data.result });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  /* 渲染今日概览 */
  _renderOverview() {
    const { classes } = this.props;
    const { overviewData } = this.state;
    let avgTimeSpent = ["-- ", <small key="s">s</small>];

    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader>
              <h4 className={classes.cardTitle}>Request</h4>
              <GridContainer style={{ textAlign: "center" }}>
                <GridItem xs={6}>
                  <p className={classes.cardCategory}>Open</p>
                  <h3 className={classes.cardTitle}>
                    {overviewData ? overviewData.open : "--"}
                  </h3>
                </GridItem>
                <GridItem xs={6}>
                  <p className={classes.cardCategory}>Draft</p>
                  <h3 className={classes.cardTitle}>
                    {overviewData ? overviewData.draft : "--"}
                  </h3>
                </GridItem>
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader>
              <h4 className={classes.cardTitle}>Response</h4>
              <GridContainer style={{ textAlign: "center" }}>
                <GridItem xs={6}>
                  <p className={classes.cardCategory}>Total</p>
                  <h3 className={classes.cardTitle}>
                    {overviewData ? overviewData.response_total : "--"}
                  </h3>
                </GridItem>
                <GridItem xs={6}>
                  <p className={classes.cardCategory}>Today</p>
                  <h3 className={classes.cardTitle}>
                    {overviewData ? overviewData.response_today : "--"}
                  </h3>
                </GridItem>
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader>
              <h4 className={classes.cardTitle}>Avg completion rate</h4>
              <p className={classes.cardCategory}>&nbsp;</p>
              <h3 className={classes.cardTitle}>
                {overviewData ? overviewData.avg_comp_rate : "--"}{" "}
                <small>%</small>
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader>
              <h4 className={classes.cardTitle}>Avg time spent</h4>
              <p className={classes.cardCategory}>&nbsp;</p>
              <h3 className={classes.cardTitle}>{avgTimeSpent}</h3>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  _prevRequests() {
    //request page number
    if (this.count > 1) {
      this.count--;
      // create a function for this
      Request.getList(this.count, 5)
        .then((response) => {
          if (this._isMount) {
            this.setState({ requestsData: response.data.result });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }
  _prevContracts() {
    //request page number
    if (this.contract_count > 1) {
      this.contract_count--;
      // create a function for this
      Request.getContracts(this.contract_count, 5)
        .then((response) => {
          if (this._isMount) {
            this.setState({ contractsData: response.data.result });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  _nextRequests() {
    if (this.count < this.state.requestsData.info.pages) {
      this.count++;

      Request.getList(this.count, 5)
        .then((response) => {
          if (this._isMount) {
            this.setState({ requestsData: response.data.result });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }
  _nextContracts() {
    if (this.contract_count < this.state.contractsData.info.pages) {
      this.contract_count++;

      Request.getContracts(this.contract_count, 5)
        .then((response) => {
          if (this._isMount) {
            this.setState({ contractsData: response.data.result });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  /* 获取 request 列表 */
  _getRequestList() {
    Request.getList(1, 5)
      .then((response) => {
        if (this._isMount) {
          this.setState({ requestsData: response.data.result });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  //get contracts and their lists
  _getcontractsList() {
    Request.getContracts(1, 5)
      .then((response) => {
        if (this._isMount) {
          this.setState({ contractsData: response.data.result });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  /* 渲染 request 列表 */
  _renderRequests() {
    const { requestsData } = this.state;
    return (
      <div>
        <div style={{ float: "right" }}>
          {this.count > 1 ? (
            <Button onClick={() => this._prevRequests()}>Prev</Button>
          ) : (
            <div></div>
          )}
          {requestsData && this.count < requestsData.info.pages ? (
            <Button onClick={() => this._nextRequests()}>Next</Button>
          ) : (
            <div></div>
          )}
        </div>
        <h4>
          Recent Requests
          {requestsData && <small> ( {requestsData.info.count} )</small>}
        </h4>
        <Card style={{ marginTop: 15, marginBottom: 15 }}>
          <CardBody>
            <Table
              // added delete here dont know if we need
              tableHead={["Title", "Type", "Questions", "Responses", "Actions"]}
              tableData={this._renderRequestsTableData()}
            />
          </CardBody>
        </Card>
        <div style={{ textAlign: "right" }}>
          <Button
            color="primary"
            onClick={() => this.props.history.push("/admin/request/survey/new")}
          >
            Create Survey
          </Button>
          <Button
            color="primary"
            onClick={() =>
              this.props.history.push("/admin/request/collection/new")
            }
            style={{ marginLeft: 15 }}
          >
            Create Collection
          </Button>
        </div>
      </div>
    );
  }

  AddressTableData() {
    const { contractsData } = this.state;
    if (!contractsData) {
      return [];
    }

    let rows = [];

    for (let i = 0, len = contractsData.list.length; i < len; ++i) {
      const request = contractsData.list[i];

      rows.push([
        <div>
          <h4>
            <b>{request.address}</b>
          </h4>
        </div>,
        <div style={{ textAlign: "center" }}>
          <h4>
            <b>{request.title}</b>
          </h4>
        </div>,
        <div style={{ textAlign: "center" }}>
          <h4>
            <b>{request.bonus_amount}</b>
          </h4>
        </div>,
        <div>
          <Badge color="success">Completed</Badge>
        </div>,
        <div></div>,
      ]);
    }
    return rows;
  }
  /* render request table data */
  _renderRequestsTableData() {
    const { requestsData } = this.state;
    if (!requestsData) {
      return [];
    }

    let rows = [];
    for (let i = 0, len = requestsData.list.length; i < len; ++i) {
      const request = requestsData.list[i];
      rows.push([
        <div>
          <h4>
            <b>{request.title}</b>
          </h4>
          <span>Created: {request.created}</span>
        </div>,
        request.type_name === "Survey" ? (
          <Badge color="primary">Survey</Badge>
        ) : (
          <Badge color="rose">Collection</Badge>
        ),
        <div style={{ textAlign: "center" }}>
          <h4>
            <b>{request.questionCount || "--"}</b>
          </h4>
          <span>Question</span>
        </div>,
        <div style={{ textAlign: "center" }}>
          <h4>
            <b>{request.count_response || 0}</b>
          </h4>
          <span>Response</span>
        </div>,
        <div>
          <div>
            <Button color="facebook" size="sm" simple>
              {request.type_name === "Collection" ? (
                <Link
                  to={{
                    pathname: `/admin/request/collection/detail/${request.id}`,
                    state: {
                      data: request,
                    },
                  }}
                  style={{ fontSize: 15 }}
                >
                  Analyse
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: `/admin/request/survey/detail/${request.id}`,
                    state: {
                      data: request,
                    },
                  }}
                  style={{ fontSize: 15 }}
                >
                  Analyse
                </Link>
              )}
            </Button>
            <span>
              <DeleteIcon
                color="secondary"
                onClick={async () => {
                  await this.handleDeleteRequest(request);
                }}
              />
            </span>
          </div>
        </div>,
      ]);
    }
    return rows;
  }

  render() {
    const { profile } = this.props;
    const { contractsData } = this.state;

    return (
      <div>
        <h3 style={{ backgroundColor: "#fff", padding: 30 }}>
          Welcome back, {profile.me.nickname} !
        </h3>
        {this._renderOverview()}
        {this._renderRequests()}

        {/* <div style={{ float: "right" }}>
          <Button onClick={() => this._prevContracts()}>Prev</Button>

          <Button onClick={() => this._nextContracts()}>Next</Button>
        </div> */}

        <div style={{ float: "right" }}>
          {this.contract_count > 1 ? (
            <Button onClick={() => this._prevContracts()}>Prev</Button>
          ) : (
            <div></div>
          )}
          {contractsData && this.contract_count < contractsData.info.pages ? (
            <Button onClick={() => this._nextContracts()}>Next</Button>
          ) : (
            <div></div>
          )}
        </div>

        <div>
          <h4>Recent Contracts</h4>
          <Card style={{ marginTop: 15, marginBottom: 15 }}>
            <CardBody>
              <Table
                tableHead={[
                  "Signer Address",
                  "Request",
                  "IPT Tokens",
                  "Status",
                ]}
                tableData={this.AddressTableData()}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  login: state.login,
  profile: state.profile,
});

const mapDispatchToProps = (dispatch, props) => ({
  checkLogin: () => dispatch(checkLogin()),
});

const connectedDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default withStyles(dashboardStyle)(connectedDashboard);
