import React, { useState } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';

// react component used to create sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';

import { Account } from '@/apis';

const styles = {
  ...sweetAlertStyle,
  ...registerPageStyle,
};

const useStyles = makeStyles(styles);

export default function RegisterPage(props) {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState();
  const [confirmPasswordState, setConfirmPasswordError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [sweetAlert, setSweetAlert] = useState(null);

  const classes = useStyles();

  const hideAlert = () => {
    setSweetAlert(null);
  };

  const handleRegister = async () => {
    let hasError = false;

    if (email.trim() === '') {
      hasError = true;
      setEmailError(true);
    }
    if (username.trim() === '') {
      hasError = true;
      setUsernameError(true);
    }
    if (password === '') {
      hasError = true;
      setPasswordError(true);
    }
    if (confirmPassword === '' || confirmPassword !== password) {
      hasError = true;
      setConfirmPasswordError(true);
    }
    if (!checked) {
      hasError = true;
    }
    if (hasError) {
      return;
    }

    try {
      const response = await Account.register(email, username, password);
      if (response.data.guest === false) {
        setSweetAlert(
          <SweetAlert
            success
            style={{ display: 'block', marginTop: '-100px', color: 'black' }}
            title="Congratulations!"
            onConfirm={() => props.history.push('/auth/login-page')}
            confirmBtnText="Ok"
            confirmBtnCssClass={classes.button + ' ' + classes.success}
          >
            A verification email has been sent to your account. Please verify
            before logging in.
          </SweetAlert>
        );
      } else {
        setSweetAlert(
          <SweetAlert
            success
            style={{ display: 'block', marginTop: '-100px', color: 'black' }}
            title="Congratulations!"
            onConfirm={() => props.history.push('/auth/login-page')}
            confirmBtnText="Ok"
            confirmBtnCssClass={classes.button + ' ' + classes.success}
          >
            You have been registered.
          </SweetAlert>
        );
      }
    } catch (error) {
      console.error('error is', error);
      setSweetAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px', color: 'black' }}
          title="Register failed"
          onConfirm={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.danger}
        >
          Please fix the problem on the registration form and try again.
        </SweetAlert>
      );
    }
  };

  return (
    <div className={classes.container}>
      {sweetAlert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={8} md={5}>
                  <div className={classes.center}>
                    <Button justIcon round color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    {` `}
                    <Button justIcon round color="dribbble">
                      <i className="fab fa-google" />
                    </Button>
                    {` `}
                    <Button justIcon round color="facebook">
                      <i className="fab fa-facebook-f" />
                    </Button>
                    {` `}
                    <h4 className={classes.socialTitle}>or be classical</h4>
                  </div>
                  <form className={classes.form}>
                    <CustomInput
                      error={usernameError}
                      labelText="Username"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'ex. john',
                        onChange: (e) => setUsername(e.target.value),
                      }}
                    />
                    <CustomInput
                      error={emailError}
                      labelText="Email"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'ex. john@gmail.com',
                        onChange: (e) => setEmail(e.target.value),
                      }}
                    />
                    <CustomInput
                      error={passwordError}
                      labelText="Password"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        placeholder: 'Alpha numberics, min 6 characters',
                        type: 'password',
                        onChange: (e) => setPassword(e.target.value),
                      }}
                    />
                    <CustomInput
                      error={confirmPasswordState}
                      labelText="Confirm Password"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        placeholder: 'Re-enter password',
                        type: 'password',
                        onChange: (e) => setConfirmPassword(e.target.value),
                      }}
                    />
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          onClick={(e) => setChecked(e.target.checked)}
                        />
                      }
                      label={
                        <span>
                          I agree to the{' '}
                          <a href="#pablo">terms and conditions</a>.
                        </span>
                      }
                    />
                    <div className={classes.center}>
                      <Button round color="primary" onClick={handleRegister}>
                        Get started
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
