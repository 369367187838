import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import combineStyles from '@/combineStyles';

// @material-ui/core components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import Button from 'components/CustomButtons/Button';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import GridContainer from 'components/Grid/GridContainer';
import CustomInput from 'components/CustomInput/CustomInput';
import GridItem from 'components/Grid/GridItem';

// @material-ui/icons
import Check from '@material-ui/icons/Check';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Star from '@material-ui/icons/StarRounded';
import StarEmpty from '@material-ui/icons/StarBorderRounded';
import Heart from '@material-ui/icons/FavoriteRounded';
import HeartEmpty from '@material-ui/icons/FavoriteBorderRounded';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbUpEmpty from '@material-ui/icons/ThumbUpOutlined';
import AddRow from '@material-ui/icons/AddCircleOutline';
import RemoveRow from '@material-ui/icons/RemoveCircleOutline';

// react component used to create sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
// import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';

import optionsTemplates from './options_templates';

/* 问题类型 */
const questionType = [
  'Dropdown', // 下拉选择
  'Multiple Choice (Radio Button)', // 单选
  'Checkboxes', // 多选
  'Star Rating', // 星评
];

/* star rating shape */
const shapeMap = {
  star: <Star style={{ color: 'orange', verticalAlign: 'middle' }} />,
  heart: <Heart style={{ color: 'red', verticalAlign: 'middle' }} />,
  thumb: <ThumbUp style={{ color: 'blue', verticalAlign: 'middle' }} />,
};

class CreateSurveyWizardStep2 extends React.Component {
  state = {
    questions: [],
    editIndex: null,
    editQuestionTitleState: '',
    editQuestion: null,
    optionsTemplate: '',
    sweetAlert: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.request !== this.props.request && prevProps.request) {
      const { entity } = prevProps.request;
      this.setState({
        questions: entity && entity.data,
      });
    }
  }

  /* 发送结果 */
  sendState() {
    return this.state.questions;
  }

  /* 是否允许下一步 */
  isValidated() {
    const { questions } = this.state;

    if (questions.length) {
      return true;
    }
    return false;
  }

  /* 渲染 questions item */
  _renderQuestionItem = (question, index) => {
    const { classes } = this.props;
    const { editIndex } = this.state;

    return editIndex === index ? (
      <div key={index}>{this._renderEditQuestion()}</div>
    ) : (
      <div
        className={classes.questionItem}
        key={index}
        onClick={() => this._editQuestion(index)}
      >
        <div className={classes.questionTitle}>
          Q{index + 1}. {question.title}
        </div>
        <div className={classes.questionOptions}>
          {question.type === 'dropdown'
            ? this._renderDropdown(question.options)
            : question.type === 'radios'
            ? this._renderRadios(question.options)
            : question.type === 'checkboxes'
            ? this._renderCheckboxes(question.options)
            : question.type === 'stars'
            ? this._renderStars(question.options)
            : null}
        </div>
      </div>
    );
  };

  /* 渲染 Dropdown */
  _renderDropdown = (options) => {
    const { classes } = this.props;

    return (
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        style={{ width: 300 }}
        value={0}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  };

  /* 渲染 checkbox */
  _renderCheckboxes = (options) => {
    const { classes } = this.props;

    return (
      <GridContainer>
        {options.map((option, i) => {
          return (
            <GridItem xs={12} sm={12} md={6} key={i}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={false}
                    tabIndex={-1}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={option}
              />
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  /* 渲染 radio */
  _renderRadios = (options) => {
    const { classes } = this.props;

    return (
      <GridContainer>
        {options.map((option, i) => {
          return (
            <GridItem xs={12} sm={12} md={6} key={i}>
              <FormControlLabel
                control={
                  <Radio
                    checked={false}
                    tabIndex={-1}
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    name='test'
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={option}
              />
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  /* 渲染 star rate */
  _renderStars = (options) => {
    const { classes } = this.props;
    const fillCount = Math.ceil(options.scale / 2);
    let stars = [],
      star = null;

    for (let i = 0; i < options.scale; ++i) {
      if (i < fillCount) {
        if (options.shape === 'heart') {
          star = (
            <Heart
              className={classes.starRating}
              key={i}
              style={{ color: 'red' }}
            />
          );
        } else if (options.shape === 'thumb') {
          star = (
            <ThumbUp
              className={classes.starRating}
              key={i}
              style={{ color: 'blue' }}
            />
          );
        } else {
          star = (
            <Star
              className={classes.starRating}
              key={i}
              style={{ color: 'orange' }}
            />
          );
        }
      } else {
        if (options.shape === 'heart') {
          star = (
            <HeartEmpty
              className={classes.starRating}
              key={i}
              style={{ color: 'pink' }}
            />
          );
        } else if (options.shape === 'thumb') {
          star = (
            <ThumbUpEmpty
              className={classes.starRating}
              key={i}
              style={{ color: 'lightblue' }}
            />
          );
        } else {
          star = (
            <StarEmpty
              className={classes.starRating}
              key={i}
              style={{ color: 'orange' }}
            />
          );
        }
      }
      stars.push(star);
    }
    return stars;
  };

  /* 编辑问题 */
  _editQuestion = (index) => {
    if (typeof this.state.questions[index] !== 'object') {
      return;
    }
    this.setState({
      optionsTemplate: '',
      editIndex: index,
      editQuestion: { ...this.state.questions[index] },
    });
  };

  /* 创建新问题 */
  _newQuestion = (typeName) => {
    const index = questionType.indexOf(typeName);
    let newQuestion = {
      title: '',
      options: ['', '', ''],
    };

    if (index === 0) {
      // dropdown
      newQuestion.type = 'dropdown';
    } else if (index === 1) {
      // radio
      newQuestion.type = 'radios';
    } else if (index === 2) {
      // checkbox
      newQuestion.type = 'checkboxes';
    } else if (index === 3) {
      // star rating
      newQuestion.type = 'stars';
      newQuestion.options = {
        scale: 5,
        shape: 'star',
      };
    }
    this.setState({
      editIndex: -1,
      editQuestion: newQuestion,
    });
  };

  /* 渲染问题编辑 */
  _renderEditQuestion = () => {
    const { classes } = this.props;
    const {
      editIndex,
      editQuestion,
      editQuestionTitleState,
      questions,
    } = this.state;

    return (
      <div className={classes.questionItemEdit}>
        <div className={classes.questionItemIndex}>
          Q{(editIndex === -1 ? questions.length : editIndex) + 1}.
        </div>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>Question</FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <CustomInput
              error={editQuestionTitleState === 'error'}
              formControlProps={{ fullWidth: true }}
              labelText={<span>(required)</span>}
              inputProps={{
                type: 'text',
                value: editQuestion.title,
                placeholder: 'Enter your question',
                onChange: (e) => {
                  editQuestion.title = e.target.value;
                  this.setState({ editQuestion });
                },
              }}
            />
          </GridItem>
        </GridContainer>
        <hr />
        {editQuestion.type === 'stars' ? (
          this._renderOptionStars(editQuestion.options)
        ) : (
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>Options</FormLabel>
            </GridItem>
            <GridItem xs={12} sm={9}>
              {this._renderOptionsTemplates()}
              {this._renderOptionRows(editQuestion.options)}
            </GridItem>
          </GridContainer>
        )}
        <div className={classes.actions}>
          <Button onClick={this._cancelEdit}>Cancel</Button>
          {editIndex >= 0 && (
            <Button
              color='danger'
              style={{ marginLeft: 15 }}
              onClick={this._deleteQuestion}
            >
              Delete
            </Button>
          )}
          <Button
            color='primary'
            style={{ marginLeft: 15 }}
            onClick={this._submitQuestion}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  /* 取消修改 */
  _cancelEdit = () => {
    this.setState({
      editIndex: null,
      editQuestion: null,
    });
  };

  /* 删除 question */
  _deleteQuestion = () => {
    this.setState({
      sweetAlert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title='Are you sure?'
          onConfirm={() => {
            // 删除
            this.setState((state) => {
              let questions = state.questions.slice();
              questions.splice(state.editIndex, 1);
              return {
                sweetAlert: null,
                questions,
                editIndex: null,
                editQuestion: null,
              };
            });
          }}
          onCancel={() => this._hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText='Yes, delete it!'
          cancelBtnText='Cancel'
          showCancel
        />
      ),
    });
  };

  /* 保存 question */
  _submitQuestion = () => {
    const { editIndex, editQuestion } = this.state;
    if (editQuestion.title.trim().length === 0) {
      // 验证标题
      return this.setState({ editQuestionTitleState: 'error' });
    }
    let options;
    if (editQuestion.type === 'stars') {
      // star rating
      options = editQuestion.options;
    } else {
      // choice
      options = [];
      for (let i = 0, len = editQuestion.options.length; i < len; ++i) {
        // 检查是否有选项
        if (editQuestion.options[i].trim().length > 0) {
          options.push(editQuestion.options[i].trim());
        }
      }
      if (options.length === 0) {
        // 找不到选项
        return this.setState({
          sweetAlert: (
            <SweetAlert
              danger
              style={{ display: 'block' }}
              title={'You are required to have at least one choice.'}
              onConfirm={this._hideAlert}
              confirmBtnCssClass={
                this.props.classes.button + ' ' + this.props.classes.danger
              }
            />
          ),
        });
      }
    }
    let newQuestion = {
      type: editQuestion.type,
      title: editQuestion.title.trim(),
      options,
    };
    if (editIndex === -1) {
      // 追加到末尾
      this.setState((state) => {
        let questions = state.questions.slice();
        questions.push(newQuestion);
        return {
          questions,
          editIndex: null,
          editQuestion: null,
        };
      });
    } else {
      // 替换问题
      this.setState((state) => {
        let questions = state.questions.slice();
        questions.splice(state.editIndex, 1, newQuestion);
        return {
          questions,
          editIndex: null,
          editQuestion: null,
        };
      });
    }
  };

  _hideAlert = () => {
    this.setState({ sweetAlert: null });
  };

  /* 更新 editQuestion.options */
  _updateOptions = (options) => {
    this.setState((state) => {
      let editQuestion = state.editQuestion;
      for (let i = options.length; i < 3; ++i) {
        // 补全到 3 个
        options.push('');
      }
      editQuestion.options = options;
      return { editQuestion };
    });
  };

  /* 更新 editQuestion.options (stars) */
  _updateOptionsStars = (field, value) => {
    this.setState((state) => {
      let editQuestion = state.editQuestion;
      let options = { ...editQuestion.options };
      options[field] = value;
      editQuestion.options = options;
      return { editQuestion };
    });
  };

  /* 修改 option 模板 */
  _changeOptionsTemplate = (templateName) => {
    this.setState({
      optionsTemplate: templateName,
    });
    const options = optionsTemplates[templateName];
    this._updateOptions(options);
  };

  /* 渲染 option 模板 */
  _renderOptionsTemplates = () => {
    const { classes } = this.props;
    const { optionsTemplate } = this.state;

    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
          Choose Template...
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          style={{ width: 300, height: 48, marginTop: 10 }}
          value={optionsTemplate}
          onChange={(e) => this._changeOptionsTemplate(e.target.value)}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Choose Template...
          </MenuItem>
          {Object.keys(optionsTemplates).map((value, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={value}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  /* 渲染 option 行 */
  _renderOptionRows = (options) => {
    const { classes } = this.props;
    let rows = [];

    for (let i = 0, len = options.length; i < len; ++i) {
      rows.push(
        <div className={classes.optionRow} key={i}>
          <CustomInput
            // error={titleState === 'error'}
            formControlProps={{ fullWidth: true, style: { paddingTop: 15 } }}
            inputProps={{
              type: 'text',
              value: options[i] || '',
              placeholder: 'Enter an answer choice',
              onChange: (e) => {
                let editQuestionOptions = this.state.editQuestion.options.slice();
                editQuestionOptions[i] = e.target.value;
                this._updateOptions(editQuestionOptions);
              },
            }}
          />
          <AddRow
            className={classes.optionAction}
            onClick={() => this._appendRow(i)}
            title='Add another choice'
          />
          <RemoveRow
            className={classes.optionAction}
            onClick={() => this._removeRow(i)}
            title='Delete this choice'
          />
        </div>
      );
    }
    return rows;
  };

  /* 渲染 stars option */
  _renderOptionStars = (options) => {
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={2}>
          <FormLabel className={classes.labelHorizontal}>Scale</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <Select
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            style={{ width: 150, height: 48, marginTop: 10 }}
            value={options.scale}
            onChange={(e) => this._updateOptionsStars('scale', e.target.value)}
          >
            {[3, 4, 5, 6, 7, 8, 9, 10].map((value, i) => (
              <MenuItem
                key={i}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={value}
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </GridItem>
        <GridItem xs={12} sm={2}>
          <FormLabel className={classes.labelHorizontal}>Shape</FormLabel>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <Select
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            style={{ width: 150, height: 48, marginTop: 10 }}
            value={options.shape}
            onChange={(e) => this._updateOptionsStars('shape', e.target.value)}
          >
            {['star', 'heart', 'thumb'].map((value, i) => (
              <MenuItem
                key={i}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={value}
              >
                {shapeMap[value]}{' '}
                <span style={{ verticalAlign: 'middle' }}>
                  {value.toUpperCase()}
                </span>
              </MenuItem>
            ))}
          </Select>
        </GridItem>
      </GridContainer>
    );
  };

  /* 添加行 */
  _appendRow = (index) => {
    let options = this.state.editQuestion.options.slice();
    options.splice(index + 1, 0, '');
    this._updateOptions(options);
  };

  /* 删除行 */
  _removeRow = (index) => {
    let options = this.state.editQuestion.options.slice();
    options.splice(index, 1);
    this._updateOptions(options);
  };

  render() {
    const { classes } = this.props;
    const { editIndex, questions, sweetAlert } = this.state;

    return (
      <div style={{ paddingLeft: 30 }}>
        {questions && questions.map(this._renderQuestionItem)}
        {editIndex === -1 ? (
          this._renderEditQuestion()
        ) : (
          <div className={classes.actions}>
            <CustomDropdown
              buttonText='New Question'
              buttonProps={{
                color: 'primary',
              }}
              dropdownHeader='Select Type'
              dropdownList={questionType}
              onClick={this._newQuestion}
            />
          </div>
        )}
        {sweetAlert}
      </div>
    );
  }
}

const questionStyle = {
  questionItem: {
    padding: 24,
    marginRight: 30,
    border: '2px solid transparent',
    '&:hover': {
      border: '2px dashed #ccc',
      cursor: 'pointer',
    },
  },
  questionItemEdit: {
    padding: 24,
    marginTop: 16,
    marginBottom: 16,
    marginRight: 30,
    border: '2px solid #ccc',
    position: 'relative',
  },
  questionItemIndex: {
    position: 'absolute',
    left: 15,
    top: 15,
    fontSize: 20,
    color: '#666',
  },
  questionTitle: {
    fontSize: 20,
    color: '#666',
    lineHeight: '1.428571429',
  },
  questionOptions: {
    marginTop: 16,
    marginLeft: 32,
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: '#666',
    fontSize: 16,
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
    transition: '0.3s ease all',
  },
  starRating: {
    width: 32,
    height: 32,
    marginRight: 8,
  },
  actions: {
    marginTop: 24,
    textAlign: 'center',
  },
  optionRow: {
    display: 'flex',
  },
  optionAction: {
    marginLeft: 10,
    marginTop: 15,
    color: '#ccc',
    cursor: 'pointer',
    width: 32,
    height: 32,
  },
};

export default withStyles(
  combineStyles(
    regularFormsStyle,
    extendedFormsStyle,
    sweetAlertStyle,
    questionStyle
  )
)(CreateSurveyWizardStep2);
