const INITIAL_STATE = {
  me: {},
  changePassError: false,
  changePassErrorMessage: {},
  changePassSuccess: false,
  updateUserError: false,
  updateUserErrorMessage: {},
  updateUserSuccess: false,
  uploadAvatarSuccess: false,
  uploadAvatarFailure: false,
  loading: false,
  profileDataError: false,
};

const profileReducer = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REINITIALIZE_STATE':
      return {
        ...currentState,
        changePassError: false,
        changePassErrorMessage: {},
        loading: false,
        changePassSuccess: false,
        updateUserError: false,
        updateUserErrorMessage: {},
        updateUserSuccess: false,
        profileDataError: false,
      };
    case 'PROFILE_DATA_REQ_SUCCESS':
      return { ...currentState, me: action.payload };
    case 'PROFILE_DATA_REQ_FAILURE':
      return { ...currentState, profileDataError: true };
    case 'UPDATE_PASS_FAILURE':
      return {
        ...currentState,
        changePassError: true,
        changePassErrorMessage: action.payload,
        loading: false,
        changePassSuccess: false,
      };
    case 'UPDATE_PASS_SUCCESS':
      return {
        ...currentState,
        changePassError: false,
        changePassErrorMessage: {},
        changePassSuccess: true,
        loading: false,
      };
    case 'UPDATE_USER_SUCCESS':
      return {
        ...currentState,
        updateUserError: false,
        updateUserErrorMessage: {},
        updateUserSuccess: true,
        loading: false,
      };
    case 'UPDATE_USER_FAILURE':
      return {
        ...currentState,
        updateUserError: true,
        updateUserErrorMessage: action.payload,
        loading: false,
        updateUserSuccess: false,
      };
    case 'UPLOAD_AVATAR_SUCCESS':
      return {
        ...currentState,
        uploadAvatarError: false,
        uploadAvatarErrorMessage: {},
        uploadAvatarSuccess: true,
        loading: false,
      };
    case 'UPLOAD_AVATAR_FAILURE':
      return {
        ...currentState,
        uploadAvatarError: true,
        uploadAvatarErrorMessage: action.payload,
        loading: false,
        uploadAvatarSuccess: false,
      };
    default:
      return currentState;
  }
};

export default profileReducer;
