import { Account } from '@/apis';

export const profileDataFetchSuccess = (data) => ({
  type: 'PROFILE_DATA_REQ_SUCCESS',
  payload: data,
});

export const profileDataFetchFailure = (error) => ({
  type: 'PROFILE_DATA_REQ_FAILURE',
  payload: error
});

export const updatePasswordSuccess = () => ({
  type: 'UPDATE_PASS_SUCCESS',
});

export const updatePasswordFailure = (error) => ({
  type: 'UPDATE_PASS_FAILURE',
  payload: error,
});

export const updateUserSuccess = () => ({
  type: 'UPDATE_NICKNAME_SUCCESS',
});

export const updateUserFailure = (error) => ({
  type: 'UPDATE_NICKNAME_FAILURE',
  payload: error,
});

export const uploadAvatarSuccess = () => ({
  type: 'UPLOAD_AVATAR_SUCCESS',
});

export const uploadAvatarFailure = (error) => ({
  type: 'UPLOAD_AVATAR_FAILURE',
  payload: error,
});

export const fetchUserProfile = () => {
  return async (dispatch) => {
    try {
      const response = await Account.getUser();
      dispatch(profileDataFetchSuccess(response.data));
    } catch (error) {
      dispatch(profileDataFetchFailure(error));
    }
  };
};

export const changePassword = (oldPassword, newPassword) => {
  return async (dispatch) => {
    try {
      await Account.changePassword(oldPassword, newPassword);
      dispatch(updatePasswordSuccess());
      dispatch(fetchUserProfile());
    } catch (error) {
      dispatch(updatePasswordFailure(error));
    }
  };
};

export const uploadAvatar = (data) => {
  return async (dispatch) => {
    try {
      await Account.uploadAvatar(data);
      dispatch(uploadAvatarSuccess());
      dispatch(fetchUserProfile());
    } catch (error) {
      dispatch(uploadAvatarFailure(error));
    }
  };
};

export const updateUser = (update) => {
  return async (dispatch) => {
    try {
      await Account.updateUser(update);
      dispatch(updateUserSuccess());
      dispatch(fetchUserProfile());
    } catch (error) {
      dispatch(updateUserFailure(error));
    }
  };
};

export const reinitializeProfileState = () => ({
  type: 'REINITIALIZE_STATE',
});
