import React, { Component } from 'react';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import '@/assets/css/querybuilder.css';
import '@/assets/css/querybuilder-compact.css';

const queryValue = {
  id: QbUtils.uuid(),
  type: 'group',
  properties: {
    conjunction: 'AND',
    not: false,
  },
};

export default class QueryBuilder extends Component {

  state = {
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), this.props.config),
    config: this.props.config,
  }

  onChange = (immutableTree, config) => {
    this.setState({ tree: immutableTree, config });

    const SQLWhere = JSON.stringify(QbUtils.sqlFormat(immutableTree, config));
    this.props.onChange(SQLWhere);
  };

  renderBuilder = (props) => (
    <div className='query-builder-container' style={{ padding: '10px' }}>
      <div className='query-builder qb-lite'>
        <Builder {...props} />
      </div>
    </div>
  );

  render = () => (
    <div>
      <Query
        {...this.props.config}
        value={this.state.tree}
        onChange={this.onChange}
        renderBuilder={this.renderBuilder}
      />
    </div>
  );
}
