import React from "react";
// import {  Link } from 'react-router-dom';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import FormLabel from "@material-ui/core/FormLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import inapp from "@/apis/inapp";

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      check: "ok",
      goNext: false,
      keyName: "",
      sweetAlert: null,
    };
  }

  isValidated = () => {
    // Commented just to work on Step 2

    let error = false;
    // pattern change to adjust to anything other than .com
    var pattern = new RegExp(
      // /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (!this.props.newRoomName) {
      error = true;
      this.props.setNameLabel("Please fill the name of the room");
      this.props.setNameError("error");
    } else {
      this.props.setNameLabel("");
      this.props.setNameError("");
    }
    if (!this.props.recipients || !pattern.test(this.props.recipients)) {
      error = true;
      this.props.setEmailLabel("Please fill the appropriate email");
      this.props.setEmailError("error");
    } else {
      this.props.setEmailLabel("");
      this.props.setEmailError("");
    }

    if (!this.props.selectedKey) {
      error = true;
    }
    if (error === true) {
      return;
    }
    this.props.handleEmailInvite();
    this.props.createRoom_demo(this.props.newRoomName);
    this.props.setNewRoomName("");
    this.props.setRecipients("");
    this.props.setSelectedKeyName("");
    error = false;

    //check for setState
    // this.setState({
    //   sweetAlert: (
    //     <SweetAlert
    //       success
    //       style={{ display: "block" }}
    //       title="Congratulations!"
    //       onConfirm={() => this.props.history.push(`/request/collection`)}
    //       confirmBtnCssClass={
    //         this.props.classes.button + " " + this.props.classes.success
    //       }
    //     />
    //   ),
    // });

    return true;
  };

  // sendState =()=> {
  //   return this.state;
  // }

  // Check if createRoomUsers will work
  checkCallBack = async () => {
    await inapp.get("/v1/vcr/getRooms", {
      // recipients: this.props.recipients,
      params: {
        user_id: this.props.userId,
      },
    });
  };

  render() {
    const { sweetAlert } = this.state;

    return (
      <div>
        {sweetAlert}
        <Card>
          <CardBody>
            <GridContainer>
              {/*<GridItem xs={12} sm={12}>
              {this.props.showStepper}
            </GridItem> */}
              <GridItem xs={12} sm={12}>
                {/*{showNavPills()}*/}
              </GridItem>
              <GridItem xs={12} sm={12}>
                <h3>Create Room</h3>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <FormLabel className={this.props.classes.labelHorizontal}>
                  {" "}
                  Room Name{" "}
                </FormLabel>
              </GridItem>
              {/* Add input*/}
              <GridItem xs={12} sm={7}>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    onChange: (e) => this.props.setNewRoomName(e.target.value),
                    value: this.props.newRoomName,
                  }}
                  labelText={this.props.nameLabel}
                  error={this.props.nameError === "error"}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <FormLabel className={this.props.classes.labelHorizontal}>
                  Enter email to send invite
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={7}>
                <CustomInput
                  name="email"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    onChange: (e) => this.props.setRecipients(e.target.value),
                    value: this.props.recipients,
                  }}
                  onChange={(e) => this.props.setRecipients(e.target.value)}
                  labelText={this.props.emailLabel}
                  type="email"
                  error={this.props.emailError === "error"}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <FormLabel className={this.props.classes.labelHorizontal}>
                  List of Keys{" "}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={5}>
                <FormControl
                  fullWidth
                  className={this.props.classes.selectFormControl}
                >
                  <InputLabel
                    // htmlFor="simple-select"
                    className={this.props.classes.selectLabel}
                  >
                    Choose Key
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: this.props.classes.selectMenu,
                    }}
                    classes={{
                      select: this.props.classes.select,
                    }}
                    value={this.props.selectedKeyName}
                    onChange={(e) => {
                      this.props.checkKeyId(e.target.value);
                    }}
                    inputProps={{
                      name: "simpleSelect",
                      id: "simple-select",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: this.props.classes.selectMenuItem,
                      }}
                    >
                      Choose Key
                    </MenuItem>
                    {this.props.userKeys.map((key) => {
                      return (
                        <MenuItem
                          key={key.id}
                          classes={{
                            root: this.props.classes.selectMenuItem,
                            selected: this.props.classes.selectMenuItemSelected,
                          }}
                          value={key.title}
                        >
                          {key.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={3} />

              <GridItem xs={12} sm={9}>
                {/*<Link to='/vcr/:id'>*/}
                <Button
                  color="info"
                  onClick={() => {
                    this.isValidated();
                  }}
                  buttonprops={{
                    style: { marginTop: "15px" },
                    color: "info",
                  }}
                  style={{ margin: "15px" }}
                >
                  Create
                </Button>
                {/*  </Link> */}
              </GridItem>
              <GridItem xs={12} sm={3} />
              <GridItem xs={12} sm={9} />
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(Step1);
