import React from "react";
import { connect } from "react-redux";
import { logout } from '@/actions';

class LogoutPage extends React.Component
{
  async componentDidMount() {
    await this.props.logout();
    this.props.history.push('/auth/login-page');
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    logout: () => dispatch(logout()),
  };
};

const connectLogoutPage = connect(null, mapDispatchToProps)(LogoutPage);

export default connectLogoutPage;
