import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/icons
import Face from "@material-ui/icons/Face";

// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

import { login, reinitializeLoginState } from "@/actions";

const useStyles = makeStyles(styles);

function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [username, setUsername] = useState("");
  const [usernameState, setUsernameState] = useState("");
  const [password, setPassword] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [sweetAlert, setSweetAlert] = useState(null);
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.login.loggedIn);
  const loggedInError = useSelector((state) => state.login.error);

  const classes = useStyles();

  useEffect(() => {
    dispatch(reinitializeLoginState());
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  // Check to see if the user is logged in
  useEffect(() => {
    if (loggedIn) {
      props.history.push("/");
    }
  }, [loggedIn, props.history]);

  useEffect(() => {
    if (loggedInError) {
      setSweetAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px", color: "black" }}
          title="Login failed"
          onConfirm={() => hideAlert()}
          confirmBtnCssClass={classes.button + " " + classes.danger}
        >
          Please enter the correct login information
        </SweetAlert>
      );
    }
  }, [loggedInError, classes.button, classes.danger]);

  const hideAlert = () => {
    setSweetAlert(null);
  };

  const handleLogin = () => {
    let hasError = false;

    if (username.trim() === "") {
      hasError = true;
      setUsernameState("error");
    }
    if (password === "") {
      hasError = true;
      setPasswordState("error");
    }
    if (hasError) {
      return;
    }

    dispatch(
      login({
        username,
        password,
      })
    );
  };

  return (
    <div className={classes.container}>
      {sweetAlert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
                <div className={classes.socialLine}>
                  {[
                    "fab fa-facebook-square",
                    "fab fa-twitter",
                    "fab fa-google-plus",
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div>
              </CardHeader>
              <CardBody>
                <CustomInput
                  error={usernameState === "error"}
                  labelText="Username"
                  id="username"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onChange: (e) => setUsername(e.target.value),
                  }}
                />
                {/* <CustomInput
                  labelText='Email...'
                  id='email'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <CustomInput
                  error={passwordState === "error"}
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    onChange: (e) => setPassword(e.target.value),
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={handleLogin}
                >
                  Let{"'"}s Go
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withRouter(LoginPage);
