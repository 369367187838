const INITIAL_STATE = {
  rooms: [],
  createRoomError: false,
  createRoomErrorMessage: {},
  createRoomSuccess: false,
  getRoomsError: false,
  getRoomsErrorMessage: {},
  getRoomsSuccess: false,
  updateAesKeyError: false,
  updateAesKeyErrorMessage: {},
  updateAesKeySuccess: false,
  updateSessionKeyError: false,
  updateSessionKeyErrorMessage: {},
  updateSessionKeySuccess: false,
  getOwnerId_error: false,
  getOwnerId_errorMessage: {},
  getOwnerIdSuccess: false,
  getDataSetSuccess: false,
  getDataSetError: false,
  getDataSetErrorMessage: {},
  isRoomOwnerSuccess: false,
  isRoomOwnerFailure: false,
  isRoomOwnerErrorMessage: {},
  addNotificationError: false,
  addNotificationErrorMessage: {},
  addNotificationSuccess: false,
  addGuestError: false,
  AddGuestErrorMessage: {},
  addGuestSuccess: false,
  loading: false,
};

export default (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REINITIALIZE_STATE':
      return {
        ...currentState,
        createRoomError: false,
        createRoomErrorMessage: {},
        createRoomSuccess: false,
        getRoomsError: false,
        getRoomsErrorMessage: {},
        getRoomsSuccess: false,
        updateAesKeyError: false,
        updateAesKeyErrorMessage: {},
        updateAesKeySuccess: false,
        updateSessionKeyError: false,
        updateSessionKeyErrorMessage: {},
        updateSessionKeySuccess: false,
        getOwnerId_error: false,
        getOwnerId_errorMessage: {},
        getOwnerIdSuccess: false,
        getDataSetSuccess: false,
        getDataSetError: false,
        getDataSetErrorMessage: {},
        isRoomOwnerSuccess: false,
        isRoomOwnerFailure: false,
        isRoomOwnerErrorMessage: {},
        addNotificationError: false,
        addNotificationErrorMessage: {},
        addNotificationSuccess: false,
        addGuestError: false,
        addGuestErrorMessage: {},
        addGuestSuccess: false,
        loading: false,
      };

    case 'GET_ROOMS_REQUEST':
      return {
        ...currentState,
        getRoomsSuccess: false,
        getRoomsError: false,
        getRoomsErrorMessage: {},
        loading: true,
      };

    case 'GET_ROOMS_SUCCESS':
      return {
        ...currentState,
        getRoomsSuccess: true,
        getRoomsError: false,
        getRoomsErrorMessage: {},
        loading: false,
        rooms: action.payload,
      };

    case 'GET_ROOMS_FAILURE':
      return {
        ...currentState,
        getRoomsError: true,
        getRoomsErrorMessage: action.payload,
        loading: false,
        getRoomsSuccess: false,
      };

    case 'GET_DATASET_REQUEST':
      return {
        ...currentState,
        getDataSetError: false,
        getDataSetErrorMessage: {},
        getDataSetSuccess: false,
        loading: true,
      };

    case 'GET_DATASET_SUCCESS':
      return {
        ...currentState,
        getDataSetError: false,
        getDataSetErrorMessage: {},
        getDataSetSuccess: true,
        loading: false,
        dataSet: action.payload,
      };

    case 'GET_DATASET_FAILURE':
      return {
        ...currentState,
        getDataSetError: true,
        getDataSetErrorMessage: action.payload,
        getDataSetSuccess: false,
        loading: false,
      };

    case 'IS_ROOM_OWNER_REQUEST':
      return {
        ...currentState,
        isRoomOwnerSuccess: false,
        isRoomOwnerFailure: false,
        isRoomOwnerErrorMessage: {},
        loading: true,
      };

    case 'IS_ROOM_OWNER_SUCCESS':
      return {
        ...currentState,
        isRoomOwnerSuccess: true,
        isRoomOwnerFailure: false,
        isRoomOwnerErrorMessage: {},
        loading: false,
        isRoomOwner: action.payload
      }; 

    case 'IS_ROOM_OWNER_FAILURE': 
      return {
        ...currentState,
        isRoomOwnerSuccess: false,
        isRoomOwnerFailure: true,
        isRoomOwnerErrorMessage: action.payload,
        loading: false,
      };

    case 'CREATE_ROOM_REQUEST':
      return {
        ...currentState,
        createRoomSuccess: false,
        createRoomError: false,
        createRoomErrorMessage: {},
        loading: true,
      };

    case 'CREATE_ROOM_SUCCESS':
      return {
        ...currentState,
        createRoomSuccess: true,
        createRoomError: false,
        createRoomErrorMessage: {},
        loading: false,
        room: action.payload,
      };

    case 'CREATE_ROOM_FAILURE':
      return {
        ...currentState,
        createRoomSuccess: false,
        createRoomError: true,
        createRoomErrorMessage: action.payload,
        loading: false,
      };

    case 'UPDATE_AES_KEY_REQUEST':
      return {
        ...currentState,
        updateAesKeySuccess: false,
        updateAesKeyError: false,
        updateAesKeyErrorMessage: {},
        loading: true,
      };

    case 'UPDATE_AES_KEY_SUCCESS':
      return {
        ...currentState,
        updateAesKeySuccess: true,
        updateAesKeyError: false,
        updateAesKeyErrorMessage: {},
        loading: false,
        currentRoomKey: action.payload,
      };

    case 'UPDATE_AES_KEY_FAILURE':
      return {
        ...currentState,
        updateAesKeySuccess: false,
        updateAesKeyError: true,
        updateAesKeyErrorMessage: action.payload,
        loading: false,
      };

    case 'UPDATE_SESSION_KEY_REQUEST':
      return {
        ...currentState,
        updateSessionKeySuccess: false,
        updateSessionKeyError: false,
        updatSessionKeyErrorMessage: {},
        loading: true,
      };

    case 'UPDATE_SESSION_KEY_SUCCESS':
      return {
        ...currentState,
        updateSessionKeySuccess: true,
        updateSessionKeyError: false,
        updateSessionKeyErrorMessage: {},
        loading: false,
        currentRoomKey: action.payload,
      };

    case 'UPDATE_SESSION_KEY_FAILURE':
      return {
        ...currentState,
        updateSessionKeySuccess: false,
        updateSessionKeyError: true,
        updateSesionKeyErrorMessage: action.payload,
        loading: false,
      };

    case 'GET_OWNER_ID_REQUEST':
      return {
        ...currentState,
        getOwnerIdSuccess: false,
        getOwnerId_error: false,
        getOwnerId_errorMessage: {},
        loading: true,
      };

    case 'GET_OWNER_ID_SUCCESS':
      return {
        ...currentState,
        getOwnerIdSuccess: true,
        getOwnerId_error: false,
        getOwnerId_errorMessage: {},
        currentRoomOwnerId: action.payload,
        loading: false,
      };
    case 'GET_OWNER_ID_FAILURE':
      return {
        ...currentState,
        getOwnerIdSuccess: false,
        getOwnerId_error: true,
        getOwnerId_errorMessage: action.payload,
        loading: false,
      };

    case 'NOTIFICATION_REQUEST':
      return {
        ...currentState,
        addNotificationError: false,
        addNotificationSuccess: false,
        addNotificationErrorMessage: {},
        loading: true,
      };

    case 'NOTIFICATION_SUCCESS':
      return {
        ...currentState,
        addNotificationError: false,
        addNotificationSuccess: true,
        addNotificationErrorMessage: {},
        loading: false,
      };

    case 'NOTIFICATION_FAILURE':
      return {
        ...currentState,
        addNotificationError: true,
        addNotificationSuccess: false,
        addNotificationErrorMessage: action.payload,
        loading: false,
      };

    case 'ADD_GUEST_REQUEST':
      return {
        ...currentState,
        addGuestError: false,
        addGuestErrorMessage: {},
        addGuestSuccess: false,
        loading: true,
      };

    case 'ADD_GUEST_SUCCESS':
      return {
        ...currentState,
        addGuestError: false,
        addGuestErrorMessage: {},
        addGuestSuccess: true,
        loading: false,
      };

    case 'ADD_GUEST_FAILURE':
      return {
        ...currentState,
        addGuestError: true,
        addGuestErrorMessage: action.payload,
        addGuestSuccess: false,
        loading: false,
      };

    default:
      return currentState;
  }
};
