import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import combineStyles from "@/combineStyles";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Wizard from "components/Wizard/Wizard";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";

import CreateDataAccessWizardStep1 from "./CreateDataAccessWizardStep1";
import CreateDataAccessWizardStep2 from "./CreateDataAccessWizardStep2";
import CreateDataAccessWizardStep3 from "./CreateDataAccessWizardStep3";

import detectEthereumProvider from "@metamask/detect-provider";

import { checkLogin } from "@/actions";
import { Request } from "@/apis";
import "@ethersproject/shims";

import { ethers } from "ethers";
import { updateUser } from "@/actions";

import { PROVIDER } from 'config/settings';
import { tokenAbi, factoryAbi } from 'config/abi';

const { Contract } = require("ethers");
const { Wallet } = ethers;

async function requestAccount() {
  await window.ethereum.request({ method: "eth_requestAccounts" });
}

class CreateDataAccessWizard extends React.Component {
  state = {
    request: null,
    sweetAlert: null,
    find_new: false,
  };

  componentDidMount() {
    // check login
    this.props.checkLogin();

    if (!this.props.login.loggedIn) {
      this.props.history.push("/auth/login-page");
    }
  }

  componentWillMount() {
    if (this.props.params && this.props.params.id) {
      // edit
      Request.getRequest("collection", this.props.params.id)
        .then((request) => {
          this.setState({ request });
        })
        .catch((e) => {
          this.setState({
            sweetAlert: (
              <SweetAlert
                danger
                style={{ display: "block" }}
                title={e.toString()}
                onConfirm={() => this.props.history.push(`/request/collection`)}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
              />
            ),
          });
        });
    }
  }

  submitting = false;
  IPT_balance = true;
  Click_count = 0;
  _submit = async (form) => {
    // this.props.updateUser(this.props.existingcredit);
    var submit_checker = false;
    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
    const factoryAddress = process.env.REACT_APP_FACTORY_ADDRESS;

    const check_metamask = await detectEthereumProvider();
    // await requestAccount();

    if (check_metamask) {
      await requestAccount();
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      var walletnew = new Wallet(process.env.REACT_APP_DEPLOYER_KEY, PROVIDER);

      const token_contract = new Contract(tokenAddress, tokenAbi, signer);
      const factory_contract = new Contract(factoryAddress, factoryAbi, walletnew);
      // check gas fee options to continue
      var options = {
        gasLimit: 60000,
        gasPrice: ethers.utils.parseUnits("800", "gwei"),
      };
      const signer_address = await signer.getAddress();
      const get_balance_account = await token_contract.balanceOf(
        signer_address
      );

      const final_balance = get_balance_account.toNumber();
      if (final_balance < form.bonusAmount) {
        this.IPT_balance = false;
      } else {
        //changed this to manually specify gas amount. Very important. Change for survey as well
        await factory_contract.createChild(tokenAddress, options);

        const length_children = await factory_contract.childrenlength();

        const all_children = await factory_contract.getChildren();

        const newchild = all_children[length_children - 1];

        form.newchild = newchild;
        form.addressSigner = signer_address;

        await token_contract
          .transfer(newchild, form.bonusAmount, options)
          .then((res) => {
            this.props.updateUser(this.props.existingcredit - form.bonusAmount);
          })
          .catch((error) => {
            console.error("Error: ", error);
            this.IPT_balance = false;
          });
        submit_checker = true;
      }
    } else {
      console.error("Not enough tokens in account selected");
    }
    try {
      if (this.submitting) {
        return false;
      }
      this.submitting = true;
      if (this.IPT_balance === false) {
      } else if (this.props.params && this.props.params.id) {
        // edit
        Request.edit("collection", this.props.params.id, form);
      } else {
        // new
        Request.create("collection", form);
      }
      if (this.IPT_balance === true && submit_checker === true) {
        this.setState({
          sweetAlert: (
            <SweetAlert
              success
              style={{ display: "block" }}
              title="Congratulations!"
              onConfirm={() => this.props.history.push(`/request/collection`)}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
            />
          ),
        });
      } else {
        this.setState({
          sweetAlert: (
            <SweetAlert
              danger
              style={{ display: "block", marginTop: "-100px", color: "black" }}
              title="Purchase Tokens to continue"
              onConfirm={() => this.props.history.push(`/request/collection`)}
              // onConfirm={() => this._hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
              }
            >
              You do not have enough tokens in this account to continue!
            </SweetAlert>
          ),
        });
      }
    } catch (e) {
      this.submitting = false;
      this.setState({
        sweetAlert: (
          <SweetAlert
            danger
            style={{ display: "block" }}
            title={e.toString()}
            onConfirm={() => this._hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
          />
        ),
      });
    }
  };

  _hideAlert = () => {
    this.setState({ sweetAlert: null });
  };

  render() {
    const { requestKeys } = this.props;
    const { request, sweetAlert } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Wizard
              validate
              steps={[
                {
                  stepName: "Summary",
                  stepComponent: CreateDataAccessWizardStep1,
                  stepId: "summary",
                  request,
                  requestKeys,
                },
                {
                  stepName: "Options",
                  stepComponent: CreateDataAccessWizardStep2,
                  stepId: "options",
                  request,
                },
                {
                  stepName: "Preview",
                  stepComponent: CreateDataAccessWizardStep3,
                  stepId: "preview",
                  request,
                },
              ]}
              title="Create Data Access Request"
              subtitle={null}
              finishButtonClick={(result) => {
                if (this.Click_count === 0) {
                  let form = result.summary;
                  form.data = result.options;
                  this._submit(form);
                } else {
                  this.Click_count = 1;
                }
              }}
            />
          </GridItem>
        </GridContainer>
        {sweetAlert}
      </div>
    );
  }
}

CreateDataAccessWizard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  login: state.login,
  requestKeys: state.keys.list,
  existingcredit: state.profile.me.credit,
});
const mapDispatchToProps = (dispatch, props) => ({
  checkLogin: () => dispatch(checkLogin()),
  updateUser: (credit) => dispatch(updateUser({ credit })),
});

const connectedCreateDataAccessWizard = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDataAccessWizard);

export default withStyles(combineStyles(dashboardStyle, sweetAlertStyle))(
  connectedCreateDataAccessWizard
);
