import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import inapp from '@/apis/inapp';

// @material-ui/core components
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Danger from 'components/Typography/Danger.js';
import Muted from 'components/Typography/Muted.js';

import SweetAlert from 'react-bootstrap-sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';

import '@ethersproject/shims';
import detectEthereumProvider from '@metamask/detect-provider';

import { ethers } from 'ethers';

import { PROVIDER } from 'config/settings';

import { tokenAbi } from 'config/abi';

const { Contract } = require('ethers');
const { Wallet } = ethers;

async function requestAccount() {
  await window.ethereum.request({ method: 'eth_requestAccounts' });
}

export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [sweetAlert, setSweetAlert] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    const { items, currency } = props;

    async function getPaymentIntent() {
      const {
        data: { clientSecret },
      } = await inapp.post('/v1/billing/paymentIntent', {
        items,
        currency,
      });
      setClientSecret(clientSecret);
    }

    getPaymentIntent();
  }, [props]);

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (event) => {
    // const { callback } = props;

    // event.preventDefault();
    // setProcessing(true);
    // const payload = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //   },
    // });
    // callback();
    // if (payload.error) {
    //   setError(`Payment failed ${payload.error.message}`);
    //   setProcessing(false);
    // } else {
    //   setError(null);
    //   setProcessing(false);
    //   setSucceeded(true);
    // }

    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;

    const check_metamask = await detectEthereumProvider();
    if (check_metamask) {
      await requestAccount();

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const signer_address = await signer.getAddress();

      var walletnew = new Wallet(process.env.REACT_APP_DEPLOYER_KEY, PROVIDER);

      const token_contract = new Contract(tokenAddress, tokenAbi, walletnew);

      const { callback } = props;

      event.preventDefault();
      setProcessing(true);
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
      callback();
      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);

        var options = {
          gasLimit: 6000000,
          gasPrice: ethers.utils.parseUnits('500', 'gwei'),
        };

        await token_contract
          .transfer(signer_address, props.purchased_credit, options)
          .then((res) => {
            setSucceeded(true);

            setSweetAlert(
              <SweetAlert
                success
                style={{
                  display: 'block',
                  marginTop: '-100px',
                  color: 'black',
                }}
                title='Congratulations'
                customIcon='https://irp-cdn.multiscreensite.com/53e775e3/dms3rep/multi/1_Ly8pp_oJ_WOVIv8fgG_LoQ.png'
                onConfirm={() => hideAlert()}
                confirmBtnCssClass={classes.button + ' ' + classes.danger}
              >
                You will recieve IPT Tokens in a moment!
              </SweetAlert>
            );

            let amountInEther = '1.0';
            // Create a transaction object
            let tx = {
              to: signer_address,
              // Convert currency unit from ether to wei
              value: ethers.utils.parseEther(amountInEther),
            };
            // Send a transaction
            walletnew.sendTransaction(tx).then((txObj) => {
              console.log('txHash', txObj.hash);
              // => 0x9c172314a693b94853b49dc057cf1cb8e529f29ce0272f451eea8f5741aa9b58
              // A transaction result can be checked in a etherscan with a transaction hash which can be obtained here.
            });
          })
          .catch((error) => {
            console.error('Error: ', error);
            setSweetAlert(
              <SweetAlert
                custom
                style={{
                  display: 'block',
                  marginTop: '-100px',
                  color: 'black',
                }}
                title='Metamask account required'
                customIcon='https://irp-cdn.multiscreensite.com/53e775e3/dms3rep/multi/1_Ly8pp_oJ_WOVIv8fgG_LoQ.png'
                onConfirm={() => hideAlert()}
                confirmBtnCssClass={classes.button + ' ' + classes.danger}
              >
                Please install meta to continue to recieve IPT tokens!
              </SweetAlert>
            );
          });
      }
    } else {
      // const hideAlert = () => {
      //   setSweetAlert(null);
      // };
      setSweetAlert(
        <SweetAlert
          custom
          style={{ display: 'block', marginTop: '-100px', color: 'black' }}
          title='Metamask account required'
          customIcon='https://irp-cdn.multiscreensite.com/53e775e3/dms3rep/multi/1_Ly8pp_oJ_WOVIv8fgG_LoQ.png'
          onConfirm={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.danger}
        >
          Please install meta to continue to recieve IPT tokens!
        </SweetAlert>
      );
      // alert("Please download metamask to continue");
    }
  };
  const hideAlert = () => {
    setSweetAlert(null);
  };

  return (
    <GridContainer>
      {sweetAlert}
      <Card>
        <CardBody>
          <CardElement id='card-element' onChange={handleChange} />
          <GridContainer>
            <GridItem md={4} />
            <GridItem md={4}>
              <Button
                disabled={processing || disabled || succeeded}
                id='submit'
                color='danger'
                onClick={handleSubmit}
              >
                <span id='button-text'>
                  {processing ? (
                    <CircularProgress size={18} color='inherit' />
                  ) : (
                    'Pay'
                  )}
                </span>
              </Button>
            </GridItem>
            <GridItem md={4} />
          </GridContainer>
          {/* Show any error that happens when processing the payment */}
          {error && <Danger>{error}</Danger>}
          {/* Show a success message upon completion */}
          {succeeded && <Muted>Payment Succeeded</Muted>}
        </CardBody>
      </Card>
    </GridContainer>
  );
}
