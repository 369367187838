import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import combineStyles from "@/combineStyles";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import BarChart from "@material-ui/icons/BarChart";
import Delete from "@material-ui/icons/Delete";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Pagination from "components/Pagination/Pagination";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";

import { checkLogin } from "@/actions";
import { Request } from "@/apis";

class ListRequests extends React.Component {
  state = {
    requestsData: null,
    sweetAlert: null,
  };

  componentDidMount() {
    const { type } = this.props;
    // check login
    this.props.checkLogin();
    if (!this.props.login.loggedIn) {
      this.props.history.push("/auth/login-page");
    }
    this._getRequestList(type);
  }

  /* 获取 request 列表 */
  _getRequestList(type, page) {
    if (!page) {
      let match = this.props.location.search.match(/page=(\d+)/);
      if (match) {
        page = parseInt(match[1], 10);
      } else {
        page = 1;
      }
    }
    Request.getList(page, 10, type)
      .then((response) => {
        this.setState({ requestsData: response.data.result });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  /* render request table data */
  _renderRequestsTableData() {
    const { requestsData } = this.state;
    if (!requestsData) {
      return [];
    }

    let rows = [];
    for (let i = 0, len = requestsData.list.length; i < len; ++i) {
      const request = requestsData.list[i];
      rows.push([
        <div>
          <h4>
            <b>{request.title}</b>
          </h4>
          <span>Created: {request.created}</span>
          <br />
        </div>,
        request.type_name === "Survey" ? (
          <Badge color="primary">Survey</Badge>
        ) : (
          <Badge color="rose">Collection</Badge>
        ),
        <div style={{ textAlign: "center" }}>
          <h4>
            <b>{request.responses || 0}</b>
          </h4>
          <span>Response</span>
        </div>,
        request.last_modified,
        <div>
          <div>
            <Button
              color="facebook"
              size="sm"
              simple
              onClick={() =>
                this.props.history.push(
                  `/request/${request.type}/edit/${request.id}`
                )
              }
            >
              <Edit /> Edit
            </Button>
          </div>
          <div>
            <Button
              color="facebook"
              size="sm"
              simple
              onClick={() =>
                this.props.history.push(
                  `/request/${request.type}/report/${request.id}`
                )
              }
            >
              <BarChart /> Analyze
            </Button>
          </div>
          <div>
            <Button
              color="danger"
              size="sm"
              simple
              onClick={() => this._delete(request)}
            >
              <Delete /> Delete
            </Button>
          </div>
        </div>,
      ]);
    }
    return rows;
  }

  /* delete */
  _delete = (request) => {
    const { type } = this.props;

    this.setState({
      sweetAlert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => {
            Request.delete(type, request.id)
              .then((result) => {
                this.setState({
                  sweetAlert: (
                    <SweetAlert
                      success
                      style={{ display: "block" }}
                      title="Deleted!"
                      onConfirm={() => this._hideAlert()}
                      confirmBtnCssClass={
                        this.props.classes.button +
                        " " +
                        this.props.classes.success
                      }
                    />
                  ),
                });
                this._getRequestList(type, this.state.requestsData.info.page);
              })
              .catch((e) => {
                console.error(e);
              });
          }}
          onCancel={() => this._hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        />
      ),
    });
  };

  _hideAlert = () => {
    this.setState({ sweetAlert: null });
  };

  render() {
    const { requestsData, sweetAlert } = this.state;

    let pages = [];
    if (requestsData && requestsData.info.pages > 1) {
      for (let i = 1; i <= requestsData.info.pages; ++i) {
        let btn = { text: i };
        if (requestsData.info.page === i) {
          btn.active = true;
        } else {
          btn.onClick = (e) => {
            e.preventDefault();
            this._getRequestList(this.props.type, i);
            this.props.history.push(`?page=${i}`);
          };
        }
        pages.push(btn);
      }
    }

    return (
      <div>
        <Card style={{ marginTop: 15, marginBottom: 15 }}>
          <CardBody>
            <Table
              tableHead={[
                "Title",
                "Type",
                "Responses",
                "Last Modified",
                "Actions",
              ]}
              tableData={this._renderRequestsTableData()}
            />
          </CardBody>
        </Card>
        <GridContainer>
          <GridItem xs={6}>
            <Button
              color="primary"
              // onClick={() => this.props.history.push("/request/survey/new")}
              onClick={() =>
                this.props.history.push("/admin/request/survey/new")
              }
            >
              Create Survey
            </Button>
            <Button
              color="primary"
              // onClick={() => this.props.history.push("/request/collection/new")}
              onClick={() =>
                this.props.history.push("/admin/request/collection/new")
              }
              style={{ marginLeft: 15 }}
            >
              Create Collection
            </Button>
          </GridItem>
          <GridItem xs={6} style={{ textAlign: "right" }}>
            <Pagination pages={pages} />
          </GridItem>
        </GridContainer>
        {sweetAlert}
      </div>
    );
  }
}

ListRequests.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  login: state.login,
  profile: state.profile,
});

const mapDispatchToProps = (dispatch, props) => ({
  checkLogin: () => dispatch(checkLogin()),
});

const connectedListRequests = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListRequests);

export default withStyles(combineStyles(dashboardStyle, sweetAlertStyle))(
  connectedListRequests
);
