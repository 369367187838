import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class CreateDataAccessWizardStep2 extends React.Component {
  state = {
    healthData: true,
    locationData: true,
    profileData: true,
    idData: true,
    personalImages: true,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.request !== this.props.request && prevProps.request) {
      const { entity } = prevProps.request;
      this.setState({
        healthData: entity && entity.data.healthData,
        locationData: entity && entity.data.locationData,
        profileData: entity && entity.data.profileData,
        idData: entity && entity.data.idData,
        personalImages: entity && entity.data.personalImages,
      });
    }
  }

  /* 发送结果 */
  sendState() {
    return this.state;
  }

  /* 是否允许下一步 */
  isValidated() {
    const { healthData, locationData, profileData, idData, personalImages } =
      this.state;

    if (healthData || locationData || profileData || idData || personalImages) {
      return true;
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    const { healthData, locationData, profileData, idData, personalImages } =
      this.state;

    return (
      <div style={{ paddingLeft: 30 }}>
        <div className={classes.checkboxAndRadio}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checked={healthData === true}
                onClick={(e) => {
                  this.setState({ healthData: e.target.checked });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Collect health and fitness data"
          />
        </div>
        <div className={classes.checkboxAndRadio}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checked={locationData === true}
                onClick={(e) => {
                  this.setState({ locationData: e.target.checked });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Collect real-time location data"
          />
        </div>
        <div className={classes.checkboxAndRadio}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checked={profileData === true}
                onClick={(e) => {
                  this.setState({ profileData: e.target.checked });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Collect user profile data"
          />
        </div>
        <div className={classes.checkboxAndRadio}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checked={idData === true}
                onClick={(e) => {
                  this.setState({ idData: e.target.checked });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="ID Data"
          />
        </div>
        <div className={classes.checkboxAndRadio}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checked={personalImages === true}
                onClick={(e) => {
                  this.setState({ personalImages: e.target.checked });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Personal Images"
          />
        </div>
      </div>
    );
  }
}

export default withStyles(regularFormsStyle)(CreateDataAccessWizardStep2);
