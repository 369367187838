import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/core components
import FormLabel from '@material-ui/core/FormLabel';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

class CreateDataAccessWizardStep3 extends React.Component {
  /* 发送结果 */
  sendState() {
    return this.state;
  }

  /* 是否允许下一步 */
  isValidated() {
    return true;
  }

  render() {
    const { classes } = this.props;
    const { summary, options } = this.props.allStates;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>Title</FormLabel>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <p>{summary ? summary.title : ''}</p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Description
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={2}>
            <p>{summary ? summary.description : ''}</p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              User Quality
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <p>{summary ? summary.quality : ''}</p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Access Type
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <p>{summary ? summary.access : ''}</p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Request Key
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <p>{summary ? summary.requestKey : ''}</p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>Bonus</FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <p>Ethereum</p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Total IPTs
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <p>{summary ? summary.bonusAmount : ''}</p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              IPTs per Response
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <p>{summary ? summary.bonusEach : ''}</p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Health Data
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <p>{options && options.healthData ? 'Include' : 'Exclude'}</p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Location Data
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <p>{options && options.locationData ? 'Include' : 'Exclude'}</p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              Profile Data
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={9}>
            <p>{options && options.profileData ? 'Include' : 'Exclude'}</p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles({})(CreateDataAccessWizardStep3);
