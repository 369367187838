// User clicks on analyze to land here. Show the table of the decrypted data.
// Card 1 ; High level details
// Card 2 : Big table showing the details of the profiles.
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import { Request } from '@/apis';

// core components
import Button from 'components/CustomButtons/Button';

import Table from 'components/Table/Table';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import SweetAlert from 'react-bootstrap-sweetalert';

import styles from 'assets/jss/material-dashboard-pro-react/views/chartsStyle.js';

import _sodium from 'libsodium-wrappers';

function CollectionDetail(props) {
  const params = useParams();
  const userKeys = useSelector((state) => state.keys.list);

  const [sweetAlert, setSweetAlert] = useState(null);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [userData, setUserData] = useState([]);
  const [requestData, setRequestData] = useState(null);
  const [sodium, setSodium] = useState(undefined);
  const requestID = params.id;

  const initLibsodium = async () => {
    await _sodium.ready;
    setSodium(_sodium);
  };

  useEffect(() => {
    async function setupRoom() {
      await _sodium.ready;
      const response = await Request.getEndUserData(requestID);
      setRequestData(props);
      setUserData(response.data.payload);
    }
    async function libcheck() {
      await initLibsodium();
    }
    libcheck();
    setupRoom();
  }, [props, requestID]);

  const _tableDataHighLevelDetail = () => {
    // get the title, type, questions etc... by calling backend here
    // response contains title, type etc
    // const response =

    let rows = [];

    if (requestData !== null) {
      const reqData = requestData.location.state.data;

      rows.push([
        <div>
          <h4>
            <b>{reqData.title}</b>
          </h4>
        </div>,
        <div>{reqData.type_name}</div>,
        <div>{requestID}</div>,
        <div>{reqData.created}</div>,
        <div>{reqData.desc}</div>,
        <div>{userData.length}</div>,
      ]);
    }
    return rows;
  };

  const get_location = (arr1) => {
    setSweetAlert(
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px', color: 'black' }}
        title='User Location'
        customIcon='https://irp-cdn.multiscreensite.com/53e775e3/dms3rep/multi/1_Ly8pp_oJ_WOVIv8fgG_LoQ.png'
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.danger}
      >
        {arr1['location'].name},{arr1['location'].street},
        {arr1['location'].region},{arr1['location'].district},
        {arr1['location'].city},{arr1['location'].country}
      </SweetAlert>
    );
  };

  const get_images = (arr1) => {
    const newarr = arr1['image_upload'].map((image, key) => {
      return (
        <li>
          <a href={image} target='_blank' rel='noopener noreferrer'>
            {image}
          </a>
        </li>
      );
    });

    setSweetAlert(
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px', color: 'black' }}
        title='Images'
        customIcon='https://irp-cdn.multiscreensite.com/53e775e3/dms3rep/multi/1_Ly8pp_oJ_WOVIv8fgG_LoQ.png'
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.danger}
      >
        {newarr}
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setSweetAlert(null);
  };
  // var arr1 = 1;
  const _tableDataOtherDetail = () => {
    let rows = [];

    if (userData.length > 0 && requestData !== null) {
      const publicKeyID = requestData.location.state.data.key;
      const userKeyPair = userKeys.filter(
        (userKey) => userKey.id === publicKeyID
      );

      if (userKeyPair[0].privateKey !== null) {
        for (let i = 0; i < userData.length; i++) {
          const request = userData[i];
          const encryptedSymmetricKey = request.encrypted_symmetric_key;

          const walletPublicKey = request.wallet_public_key;

          const privateKey = userKeyPair[0].privateKey;

          const hexStringByte = sodium.from_hex(encryptedSymmetricKey);

          const ciphertext = hexStringByte.slice(24);
          const realNonce = hexStringByte.slice(0, 24);

          const decryptedSymmetricKey = sodium.crypto_box_open_easy(
            ciphertext,
            realNonce,
            sodium.from_hex(walletPublicKey),
            sodium.from_hex(privateKey)
          );

          const encryptedData = request.data;
          const encryptedDataByte = sodium.from_hex(encryptedData);

          const cipherData = encryptedDataByte.slice(24);
          const nonce = encryptedDataByte.slice(0, 24);

          const decryptedData = sodium.crypto_secretbox_open_easy(
            cipherData,
            nonce,
            decryptedSymmetricKey
          );

          let arr = sodium.to_string(decryptedData);
          //Get back soon
          let arr1 = JSON.parse(arr);

          rows.push([
            <div>
              <h4>
                <b>{request.member_id}</b>
              </h4>
            </div>,
            // <div>{request.data || "--"}</div>,
            // <div>{request.req_id || 0}</div>,
            // <div>{request.encrypted_symmetric_key}</div>,
            // <div>{request.wallet_public_key}</div>,
            <div>{arr1['firstName']}</div>,
            <div>{arr1['lastName']}</div>,
            <div>{arr1['age']}</div>,
            <div>{arr1['mobile']}</div>,
            <div>{arr1['email']}</div>,
            <div>{arr1['gender']}</div>,
            <div>
              {arr1['location'] ? (
                <Button
                  color='facebook'
                  size='sm'
                  simple
                  onClick={() => get_location(arr1)}
                >
                  Location
                </Button>
              ) : (
                'Disabled'
              )}
            </div>,

            <div>
              {arr1['image_upload'] ? (
                <Button
                  color='facebook'
                  size='sm'
                  simple
                  onClick={() => get_images(arr1)}
                >
                  Expand for Images
                </Button>
              ) : (
                'None'
              )}
            </div>,
          ]);
        }
      }
    }
    return rows;
  };

  const getrows = _tableDataOtherDetail();

  if (getrows.length > 0) {
    return (
      <div>
        <Card>
          {sweetAlert}

          <CardBody>
            <h1>Request Detail</h1>
            <Table
              tableHead={[
                'Title',
                'Type',
                'Request ID', //remove the key and put request id
                'Created',
                'Description',
                'Responses',
              ]}
              tableData={_tableDataHighLevelDetail()}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h1>Responses</h1>
            <Table
              tableHead={[
                'Member ID',
                // "Data",
                // "Request ID",
                // "Encrypted Symmetric Key",
                // "Wallet Public Key",
                'First Name',
                'Last Name',
                'Age',
                'Mobile',
                'Email',
                'Gender',
                'Location',
                'Images',
              ]}
              tableData={_tableDataOtherDetail()}
            />
          </CardBody>
        </Card>
      </div>
    );
  } else {
    return (
      <div>
        <Card>
          <CardBody>
            <CardHeader>NOBODY ACCEPTED ON THE PHONE APPLICATION</CardHeader>
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default CollectionDetail;
