export default (
  state = {},
  action
) => {
  switch (action.type) {
    case 'LOGIN': {
      return {
        login: true,
        ...action.payload,
      };
    }

    case 'LOGOUT':
      return {
        login: false,
        account: {},
      };
    case 'EDIT':
      return {
        account: { ...state.account, nickname: action.payload },
      };
    default:
      return state;
  }
};
