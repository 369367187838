import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { CSVReader } from 'react-papaparse';
import _sodium from 'libsodium-wrappers';
import { VCR } from '@/apis';

// react plugin for creating charts
import ChartistGraph from 'react-chartist';
//import List from 'react-virtualized';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
//import 'react-virtualized/styles.css';

//import Sweet Alert from bootstrap
import SweetAlert from 'react-bootstrap-sweetalert';

// material-ui icons
import Check from '@material-ui/icons/Check';
import Timeline from '@material-ui/icons/Timeline';

// core components
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
//import { Column, Table, SortDirection, AutoSizer } from "react-virtualized";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import QueryBuilder from 'inComponents/QueryBuilder/QueryBuilder';
import loadedConfig, {
  buildConfigFields,
} from 'inComponents/QueryBuilder/config';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react';

import chartStyles from 'assets/jss/material-dashboard-pro-react/views/chartsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import Uploadform from './Modules/uploadform';
import { verifyLength } from '@/utils';

import {
  updateAesKey,
  updateSessionKey,
  getOwnerId,
  getDataSet,
  isRoomOwner,
  addNotification,
  addGuest,
} from '@/actions';

//get eth data for IPT Token deduction

import '@ethersproject/shims';
import detectEthereumProvider from '@metamask/detect-provider';

import { ethers } from 'ethers';
import { PROVIDER } from 'config/settings';
import { tokenAbi } from 'config/abi';

const { Contract } = require('ethers');
const { Wallet } = ethers;

const styles = {
  ...extendedFormsStyle,
  ...chartStyles,
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  backdrop: {
    zIndex: 1201,
    color: '#fff',
  },
};

const useStyles = makeStyles(styles);

function RoomWigetLocalEncrypt(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const dataSet = useSelector((state) => state.vcr.dataSet);

  const loggedIn = useSelector((state) => state.login.loggedIn);
  const userId = useSelector((state) => state.profile.me.user_id);
  const userKeys = useSelector((state) => state.keys.list);
  const userRooms = useSelector((state) => state.vcr.rooms);
  const isOwner = useSelector((state) => state.vcr.isRoomOwner);
  const owner_id = useSelector((state) => state.vcr.currentRoomOwnerId);

  const room_id = params.id;

  const [addGuestValue, setAddGuestValue] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [campaignNameState, setCampaignNameState] = useState();
  const [checked, setChecked] = useState([]);
  const [checkedColumns, setCheckedColumns] = useState([]);
  const [clearBox, setClearBox] = useState('false');
  const [csvData, setCsvData] = useState([]);
  const [dataSetName, setDataSetName] = useState('');
  const [doneLocalEncrypt, setDoneLocalEncrypt] = useState(false);
  const [header, setHeader] = useState([]);
  const [labels, setLabels] = useState([]);
  const [uniqueSet, setUniqueSet] = useState([]);
  const [overlap, setOverlap] = useState(0);
  const [series, setSeries] = useState([]);
  const [percentOfSet, setPercentOfSet] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [receivers, setReceivers] = useState('');
  const [selectedKeyName, setSelectedKeyName] = useState('');
  const [selectedKey, setSelectedKey] = useState('');
  const [sessionKey, setSessionKey] = useState('');
  const [sodium, setSodium] = useState(undefined);
  const [showGraphs, setShowGraphs] = useState(false);
  const [showLink, setShowLink] = useState(true);
  const [sqlWhere, setSqlWhere] = useState(undefined);
  const [uploadField, setUploadField] = useState(false);
  const [aesKeyExists, setAesKeyExists] = useState(false);

  const [compareLoader, setCompareLoader] = useState(false);

  const [roomUserCount, setRoomUserCount] = useState(0);
  //const[keyPair,setKeyPair]=useState(0);
  const [entered, setEntered] = useState(false);

  const initLibsodium = async () => {
    await _sodium.ready;
    setSodium(_sodium);
  };

  useEffect(() => {
    async function setupRoom() {
      await _sodium.ready;
      const sodium = _sodium;
      const counter = await VCR.getRoomUsersCount(room_id);

      setRoomUserCount(counter.data.resp);
      const res1 = await VCR.getUserType(room_id); //Get user type owner or guest
      const isOwner = res1.data.resp[0].type === 'Owner';

      const res2 = await VCR.getRoomKeysDetails(room_id); //Get the keys of the user

      if (res2.data.roomKeysDetails.length > 0) {
        const publicKeyID = res2.data.roomKeysDetails[0].public_key_id; //public key id
        const userKeyPair = userKeys.filter(
          (userKey) => userKey.id === publicKeyID
        );

        // if (userKeyPair[0].privateKey) {
        //   setEntered(true);
        // }

        // if (!userKeyPair[0].privateKey) {
        //   setEntered(false);
        // }

        if (res2.data.roomKeysDetails.length === 0) {
          setAesKeyExists(false);
        } else if (!res2.data.roomKeysDetails[0].aes_key) {
          //if the user's key is not yet in db
          if (userKeyPair[0].privateKey) {
            setAesKeyExists(false);
            if (isOwner) {
              //If the user is an owner
              const userRoom = userRooms.filter(
                (userRoom) => userRoom.room_id === room_id
              );
              const [guest] =
                userRoom[0].vcr_Room.aesKeys.length > 0
                  ? userRoom[0].vcr_Room.aesKeys.filter(
                      (aeskey) => aeskey.user_id !== userId
                    )
                  : null;

              if (guest) {
                const guestPublicKey = await VCR.getPublicKey(
                  room_id,
                  guest.user_id
                );

                const sessionKey = sodium.to_hex(
                  sodium.crypto_kx_server_session_keys(
                    sodium.from_hex(userKeyPair[0].publicKey),
                    sodium.from_hex(userKeyPair[0].privateKey),
                    sodium.from_hex(guestPublicKey)
                  ).sharedTx
                );

                //Generating encrpyted Session key in array format
                const encryptedSessionKey = sodium.crypto_box_seal(
                  sessionKey,
                  sodium.from_hex(userKeyPair[0].publicKey)
                );
                setSessionKey(sessionKey);

                dispatch(
                  updateSessionKey(
                    userId,
                    room_id,
                    publicKeyID,
                    sodium.to_hex(encryptedSessionKey)
                  )
                );
                setAesKeyExists(true);
              }
            }
          } else {
            setAesKeyExists(false);
          }
        } //If user's key is already in the db
        else {
          if (
            !isOwner &&
            entered &&
            res2.data.roomKeysDetails[0].aes_key !== 'waitingforsessionkey'
          ) {
            //If user is guest and the guest's key has been approved
            setAesKeyExists(true);

            const decryptedSessionKey = sodium.crypto_box_seal_open(
              sodium.from_hex(res2.data.roomKeysDetails[0].aes_key),
              sodium.from_hex(userKeyPair[0].publicKey),
              sodium.from_hex(userKeyPair[0].privateKey)
            );

            setSessionKey(decryptedSessionKey);
          } else if (
            !isOwner &&
            !entered &&
            res2.data.roomKeysDetails[0].aes_key !== 'waitingforsessionkey'
          ) {
            setAesKeyExists(true);
          } else if (
            !isOwner &&
            res2.data.roomKeysDetails[0].aes_key === 'waitingforsessionkey'
          ) {
            //If user is guest and the guest's key has NOT been approved
            setSessionKey('dummy');
            setAesKeyExists(true);
          } //If user is owner
          else if (isOwner && entered) {
            setAesKeyExists(true);
            const decryptedSessionKey = sodium.crypto_box_seal_open(
              sodium.from_hex(res2.data.roomKeysDetails[0].aes_key),
              sodium.from_hex(userKeyPair[0].publicKey),
              sodium.from_hex(userKeyPair[0].privateKey)
            );
            setSessionKey(decryptedSessionKey);
            const userRoom = userRooms.filter(
              (userRoom) => userRoom.room_id === room_id
            );

            const [guest] =
              userRoom[0].vcr_Room.aesKeys.length > 0
                ? userRoom[0].vcr_Room.aesKeys.filter(
                    (aeskey) =>
                      aeskey.aes_key === 'waitingforsessionkey' &&
                      aeskey.user_id !== userId
                  )
                : null;

            if (guest) {
              const guestPublicKey = await VCR.getPublicKey(
                room_id,
                guest.user_id
              );

              const guestEncryptedSessionKey = sodium.crypto_box_seal(
                decryptedSessionKey,
                sodium.from_hex(guestPublicKey)
              );

              dispatch(
                updateSessionKey(
                  guest.user_id,
                  room_id,
                  guest.public_key_id,
                  sodium.to_hex(guestEncryptedSessionKey)
                )
              );
            }
          } else if (isOwner && !entered) {
            setAesKeyExists(true);
          }
        }
      }
    }
    if (!loggedIn) {
      props.history.push('/auth/login-page');
    } else {
      let mounted = true;

      if (mounted) {
        async function libcheck() {
          await initLibsodium();
        }
        libcheck();
        dispatch(getOwnerId(room_id));
        dispatch(getDataSet(room_id));
        dispatch(isRoomOwner(room_id));
        setupRoom();
      }

      return () => {
        mounted = false;
      };
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entered]);

  useEffect(() => {
    async function setupGraph() {
      if (uniqueSet.length === checked.length && checked.length > 0) {
        let sum = uniqueSet.reduce((pv, cv) => pv + cv, 0);
        sum = sum + overlap;
        for (let i = 0; i < uniqueSet.length; i++) {
          let temp = uniqueSet[i] / sum;
          setLabels((labels) => [...labels, Math.round(temp * 100) + '%']);
          setSeries((series) => [...series, Math.round(temp * 100)]);
        }
        setLabels((labels) => [
          ...labels,
          Math.round((overlap / sum) * 100) + '%',
        ]);
        setSeries((series) => [...series, Math.round((overlap / sum) * 100)]);
      }
    }
    setupGraph();
  }, [uniqueSet, checked.length, overlap]);

  useEffect(() => {
    async function crashhandle() {
      const counter = await VCR.getRoomUsersCount(room_id);
      setRoomUserCount(counter.data.resp);
      const res2 = await VCR.getRoomKeysDetails(room_id); //Get the keys of the user
      if (res2.data.roomKeysDetails.length > 0) {
        const publicKeyID = res2.data.roomKeysDetails[0].public_key_id; //public key id
        const userKeyPair = userKeys.filter(
          (userKey) => userKey.id === publicKeyID
        );

        if (userKeyPair[0].privateKey) {
          setEntered(true);
        }

        if (!userKeyPair[0].privateKey) {
          setEntered(false);
        }
      }
    }
    if (!loggedIn) {
      props.history.push('/auth/login-page');
    } else {
      let mounted = true;
      if (mounted) {
        crashhandle();
      }
      return () => {
        mounted = false;
      };
    }
  }, [loggedIn, props.history, room_id, userKeys]);

  const checkKeyId = (name) => {
    const keyId = userKeys.filter((key) => key.title === name)[0].id;

    setSelectedKeyName(name);
    setSelectedKey(keyId);
  };

  async function requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
  }

  const sweetAlertCompare = async () => {
    //setCompareLoader
    setCompareLoader(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px', color: 'black' }}
        title='Are you sure?'
        showCancel
        confirmBtnText='Continue'
        onConfirm={() => confirmAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.danger}
      >
        To Continue 1 IPT Token will be deducted
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setCompareLoader(null);
  };

  const confirmAlert = async () => {
    await hideAlert();
    await handleCompare();
  };

  const handleCompare = async () => {
    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
    var checker = false;
    const check_metamask = await detectEthereumProvider();
    if (check_metamask) {
      await requestAccount();

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      var walletnew = new Wallet(process.env.REACT_APP_DEPLOYER_KEY, PROVIDER);
      const walletnew_address = await walletnew.getAddress();

      const token_contract = new Contract(tokenAddress, tokenAbi, signer);

      var options = {
        gasLimit: 60000,
        gasPrice: ethers.utils.parseUnits('500', 'gwei'),
      };

      await token_contract
        .transfer(walletnew_address, 1, options)
        .then((res) => {
          checker = true;
          setCompareLoader(
            <SweetAlert
              success
              style={{ display: 'block', marginTop: '-100px', color: 'black' }}
              title='Congratulations'
              onConfirm={() => hideAlert()}
              confirmBtnCssClass={classes.button + ' ' + classes.danger}
            ></SweetAlert>
          );
        })
        .catch((error) => {
          console.error('Error: ', error);
          setCompareLoader(
            <SweetAlert
              danger
              style={{ display: 'block', marginTop: '-100px', color: 'black' }}
              title='Purchase IPT Tokens to continue'
              customIcon='https://irp-cdn.multiscreensite.com/53e775e3/dms3rep/multi/1_Ly8pp_oJ_WOVIv8fgG_LoQ.png'
              onConfirm={() => hideAlert()}
              confirmBtnCssClass={classes.button + ' ' + classes.danger}
            ></SweetAlert>
          );
        });

      if (checker) {
        const result = await VCR.getReport(room_id, checked);
        setOverlap(result.data.result.length - 1);
        for (let i = 0; i < checked.length; i++) {
          let temp = Math.round(
            ((result.data.result.length - 1) /
              (result.data.datasetCount[i] - 1)) *
              100
          );
          setPercentOfSet((percentOfSet) => [...percentOfSet, temp]);
          setUniqueSet((uniqueSet) => [
            ...uniqueSet,
            result.data.datasetCount[i] - result.data.result.length,
          ]);
        }

        setShowGraphs(true);

        let newencryptedCSV = new Array(result.data.result.length);
        for (let i = 0; i < result.data.result.length; i++) {
          newencryptedCSV[i] = Object.values(result.data.result[i]);
        }

        setCsvData(result.data.result);
        setShowLink(true);
      }
      //*IMP*//
      // const result = await VCR.getReport(room_id, checked);
      // setOverlap(result.data.result.length - 1);
      // for (let i = 0; i < checked.length; i++) {
      //   let temp = Math.round(
      //     ((result.data.result.length - 1) / (result.data.datasetCount[i] - 1)) *
      //       100
      //   );
      //   setPercentOfSet((percentOfSet) => [...percentOfSet, temp]);
      //   setUniqueSet((uniqueSet) => [
      //     ...uniqueSet,
      //     result.data.datasetCount[i] - result.data.result.length,
      //   ]);
      // }
      //NOT IMP//
      // setPercentOfSet1(
      //   Math.round(
      //     ((result.data.result.length - 1) / (result.data.dataset1Count - 1)) *
      //       100
      //   )
      // );
      // setPercentOfSet2(
      //   Math.round(
      //     ((result.data.result.length - 1) / (result.data.dataset2Count - 1)) *
      //       100
      //   )
      // );
      // setPercentOfSet3(
      //   Math.round(
      //     ((result.data.result.length - 1) / (result.data.dataset3Count - 1)) *
      //       100
      //   )
      // );

      // setUniqueSet1(result.data.dataset1Count - result.data.result.length);
      // setUniqueSet2(result.data.dataset2Count - result.data.result.length);
      // setUniqueSet3(result.data.dataset3Count - result.data.result.length);

      //IMP ***//
      // setShowGraphs(true);

      // let newencryptedCSV = new Array(result.data.result.length);
      // for (let i = 0; i < result.data.result.length; i++) {
      //   newencryptedCSV[i] = Object.values(result.data.result[i]);
      // }

      // setCsvData(result.data.result);
      // setShowLink(true);
    }
  };

  const getDataSetColumns = async (dataset_id) => {
    try {
      const {
        data: { columns: dataSetColumns },
      } = await VCR.getDataSetColumns(room_id, dataset_id);

      return dataSetColumns;
    } catch (error) {
      console.error('Get dataset columns error: ', error);
      return [];
    }
  };

  const renderQueryBuilder = () => {
    const firstSet = dataSet.filter((set) => set.id === checked[0]);
    const secondSet = dataSet.filter((set) => set.id === checked[1]);

    const dataSet1Config = buildConfigFields(
      firstSet[0].name,
      checkedColumns[0]
    );

    const dataSet2Config = buildConfigFields(
      secondSet[0].name,
      checkedColumns[1]
    );

    const config = {
      fields: {
        ...dataSet1Config,
        ...dataSet2Config,
      },
      ...loadedConfig,
    };

    return (
      <QueryBuilder config={config} onChange={(query) => setSqlWhere(query)} />
    );
  };

  const handleJoin = async () => {
    const firstSet = dataSet.filter((set) => set.id === checked[0]);
    const secondSet = dataSet.filter((set) => set.id === checked[1]);

    let whereClause = '';
    if (typeof sqlWhere !== 'undefined') {
      whereClause = sqlWhere.replaceAll(firstSet[0].name, firstSet[0].id);
      whereClause = whereClause.replaceAll(secondSet[0].name, secondSet[0].id);
      whereClause = whereClause.replace(/[\])}[{(]/g, '');
      whereClause = whereClause.replace(/['"]+/g, '');
    }

    const result = await VCR.joinDatasets(
      room_id,
      checked[0],
      checked[1],
      whereClause
    );

    let newencryptedCSV = new Array(result.data.result.length);
    for (let i = 0; i < result.data.result.length; i++) {
      newencryptedCSV[i] = Object.values(result.data.result[i]);
    }
    setCsvData(result.data.result);
    setShowLink(true);
  };

  const handleDelete = async () => {
    for (const dataset_id of checked) {
      await VCR.deleteDataSet(room_id, dataset_id);
    }
    setCsvData([]);
    dispatch(getDataSet(room_id));
    setCheckedColumns([]);
    setChecked([]);
    setShowGraphs(false);
  };

  const handleToggle = async (value) => {
    const currentIndex = checked.indexOf(value);
    const columns = await getDataSetColumns(value);

    setHeader(columns);
    const newCheckedColumns = [...checkedColumns];
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      newCheckedColumns.push(columns);
    } else {
      newChecked.splice(currentIndex, 1);
      newCheckedColumns.splice(currentIndex, 1);
    }

    setCheckedColumns(newCheckedColumns);
    setChecked(newChecked);
  };

  const handleDecrypt = async (data) => {
    const firstSet = dataSet.filter((set) => set.id === checked[0]);
    //const secondSet = dataSet.filter((set) => set.id === checked[1]);
    data.shift(header);
    const decrypted = data.map((row) => {
      let decryptedRow = new Array(Object.keys(row).length);
      for (let i = 0; i < Object.keys(row).length; i++) {
        //if header[i] is in firstSet array
        if (
          (firstSet[0].filtered != null &&
            firstSet[0].filtered.split(',').includes(header[i]) === true) ||
          firstSet[0].filtered == null
        ) {
          const encrypted = sodium.from_hex(row[Object.keys(row)[i]]);
          let sliced = encrypted.slice(0, sodium.crypto_secretbox_NONCEBYTES);
          let ciphertext = encrypted.slice(sodium.crypto_secretbox_NONCEBYTES);
          decryptedRow[i] = sodium.to_string(
            sodium.crypto_secretbox_open_easy(
              ciphertext,
              sliced,
              sodium.from_hex(sessionKey)
            )
          );
        }
        if (
          firstSet[0].filtered != null &&
          firstSet[0].filtered.split(',').includes(header[i]) === false
        ) {
          decryptedRow[i] = row[Object.keys(row)[i]];
        }
      }
      return decryptedRow;
    });
    decrypted.unshift(header);
    setCsvData(decrypted);
  };

  const encrypted = useMemo(() => {
    const encrypted = rawData.map(({ data }) => {
      let encryptedRow = new Array(data.length);
      let decryp = new Array(data.length);

      for (let i = 0; i < data.length; i++) {
        const nounce = sodium
          .crypto_generichash(32, data[i].toLowerCase())
          .slice(0, sodium.crypto_secretbox_NONCEBYTES);

        const cipher = sodium.crypto_secretbox_easy(
          data[i].toLowerCase(),
          nounce,
          sodium.from_hex(sessionKey)
        );

        let c = new Uint8Array(cipher.length + nounce.length);
        c.set(nounce);
        c.set(cipher, nounce.length);

        encryptedRow[i] = sodium.to_hex(c);

        let sliced = c.slice(0, sodium.crypto_secretbox_NONCEBYTES);
        let ciphertext = c.slice(sodium.crypto_secretbox_NONCEBYTES);
        decryp[i] = sodium.crypto_secretbox_open_easy(
          ciphertext,
          sliced,
          sodium.from_hex(sessionKey)
        );
      }
      return encryptedRow;
    });
    encrypted.unshift(header);
    return encrypted;
  }, [rawData, sessionKey, sodium, header]);

  const handleOnDrop = (data) => {
    //For ENCRYPTION OF DATA
    const headertemp = data.shift().data;
    for (let i = 0; i < headertemp.length; i++) {
      const word = headertemp[i];
      const replacement = word.replace(/[^a-zA-Z0-9]/g, '');
      headertemp[i] = replacement;
    }
    setHeader(headertemp);
    setRawData(data);
    setDoneLocalEncrypt(true);
    setUploadField(true);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.error(err);
  };

  const handleOnRemoveFile = (data) => {
    setCampaignName('');
    setHeader([]);

    setDoneLocalEncrypt(false);
    setUploadField(false);
  };

  const handleUpload = async () => {
    if (campaignNameState === 'success') {
      await VCR.uploadEncrypted(campaignName, room_id, header, encrypted);

      dispatch(getDataSet(room_id));
      setDataSetName('');
      setUploadField(false);
      setDoneLocalEncrypt(false);
      setClearBox(true);
      setClearBox(false);
    }
  };

  const renderDataSets = () => {
    return (
      <div className={classes.inlineChecks}>
        {dataSet.map((row) => {
          return (
            <FormControlLabel
              key={row.id}
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() => handleToggle(row.id)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{ label: classes.label }}
              label={row.name}
            />
          );
        })}
      </div>
    );
  };

  const handleGuestSelectKey = async () => {
    for (let i = 0; i < userKeys.length; i++) {
      if (userKeys[i].id === selectedKey) {
        const ownerPublicKey = await VCR.getPublicKey(room_id, owner_id);
        if (roomUserCount < 3) {
          //In case of only one guest
          const sessionKey = sodium.to_hex(
            sodium.crypto_kx_client_session_keys(
              sodium.from_hex(userKeys[i].publicKey),
              sodium.from_hex(userKeys[i].privateKey),
              sodium.from_hex(ownerPublicKey)
            ).sharedRx
          );
          setSessionKey(sessionKey);

          const encryptedSessionKey = sodium.crypto_box_seal(
            sessionKey,
            sodium.from_hex(userKeys[i].publicKey)
          );
          dispatch(
            updateAesKey(
              userId,
              room_id,
              selectedKey,
              sodium.to_hex(encryptedSessionKey)
            )
          );
        }
        if (roomUserCount > 2) {
          dispatch(
            updateAesKey(userId, room_id, selectedKey, 'waitingforsessionkey')
          );
        }
      }
    }

    dispatch(
      addNotification(
        'The guest has entered his key for room ' + room_id,
        owner_id
      )
    );

    setCompareLoader(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '-100px', color: 'black' }}
        title='Successfully Entered The Room!'
        onConfirm={() => props.history.push('/admin/vcr')}
        confirmBtnCssClass={classes.button + ' ' + classes.danger}
      >
        Visit Room again to compare data
      </SweetAlert>
    );
  };

  const getPercentofSet = () => {
    let rows = [];
    for (let i = 0; i < percentOfSet.length; i++) {
      rows.push([
        <GridItem key={i}>
          <Card>
            <CardHeader color='danger' icon>
              <CardIcon color='danger'>
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Percentage of Set {i + 1}
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={{
                  labels: [percentOfSet[i] + '%', 100 - percentOfSet[i] + '%'],
                  series: [percentOfSet[i], 100 - percentOfSet[i]],
                }}
                type='Pie'
                options={{ height: '230px' }}
              />
            </CardBody>
          </Card>
        </GridItem>,
      ]);
    }
    return rows;
  };
  return (
    <div>
      {aesKeyExists && sessionKey !== 'dummy' && entered && (
        //Condition one when you have the aes keys uploaded as an owner or guest

        <GridContainer>
          {compareLoader}

          <Card>
            <CardBody>
              <GridItem>
                <CSVReader
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  noClick
                  isReset={clearBox}
                  addRemoveButton
                  onRemoveFile={handleOnRemoveFile}
                >
                  <span>Drop CSV file here to encrypt.</span>
                </CSVReader>
              </GridItem>
              <br />
              {encrypted.length > 0 && (
                <GridItem>
                  {doneLocalEncrypt && (
                    <GridContainer justify='center'>
                      <GridItem>
                        <p>
                          The file has been encrypted locally. Enter a name for
                          the data set and click on the upload button to upload
                          it to the Virtual Clean Room.
                        </p>
                      </GridItem>
                      <GridItem>
                        <CSVLink
                          data={encrypted}
                          filename={'EncryptedFile.csv'}
                          enclosingCharacter={''}
                        >
                          Click here to save the encrypted file.
                        </CSVLink>
                      </GridItem>
                    </GridContainer>
                  )}
                  {uploadField === true && (
                    <GridItem>
                      <Uploadform
                        handleupload={handleUpload}
                        campaignnamestate={campaignNameState}
                        dataset={dataSet}
                        setcampaignnamestate={setCampaignNameState}
                        setcampaignname={setCampaignName}
                        verifylength={verifyLength}
                        setDataSetName={setDataSetName}
                        dataSetName={dataSetName}
                      />
                    </GridItem>
                  )}
                </GridItem>
              )}
            </CardBody>
          </Card>
          {dataSet.length > 0 && (
            <Card>
              <CardBody>
                <GridContainer direction='column'>
                  <GridItem>
                    <GridContainer
                      direction='row'
                      justify='center'
                      alignItems='center'
                    >
                      <h4>Data Sets:</h4>
                    </GridContainer>
                    <GridContainer
                      direction='row'
                      justify='center'
                      alignItems='center'
                    >
                      {renderDataSets()}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                {checked.length === 2 && (
                  <GridContainer
                    direction='column'
                    style={{ marginTop: '4em' }}
                  >
                    <GridContainer
                      direction='row'
                      justify='center'
                      alignItems='center'
                    >
                      <GridItem>
                        <h6>
                          Use the query builder to set join conditions on the
                          data sets
                        </h6>
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      direction='row'
                      justify='center'
                      alignItems='center'
                    >
                      <GridItem>{renderQueryBuilder()}</GridItem>
                    </GridContainer>
                  </GridContainer>
                )}
                <GridContainer justify='center'>
                  <GridItem>
                    <Button
                      color='primary'
                      onClick={async () => {
                        // await handleCompare();
                        await sweetAlertCompare();
                      }}
                      disabled={checked.length === 0}
                    >
                      Compare
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      color='primary'
                      onClick={async () => {
                        await handleJoin();
                      }}
                      disabled={
                        checked.length !== 2 || typeof sqlWhere === 'undefined'
                      }
                    >
                      Join
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      color='rose'
                      onClick={handleDelete}
                      disabled={checked.length < 1}
                    >
                      Delete
                    </Button>
                  </GridItem>
                </GridContainer>
                {csvData.length > 0 && showLink === true && (
                  <GridContainer justify='center'>
                    <GridItem>
                      <CSVLink
                        data={csvData}
                        onClick={async () => {
                          await handleDecrypt(csvData);
                          setShowLink(false);
                        }}
                        filename={'report.csv'}
                        enclosingCharacter={''}
                      >
                        Click here to decrypt and download the report
                      </CSVLink>
                    </GridItem>
                  </GridContainer>
                )}
              </CardBody>
            </Card>
          )}

          {showGraphs && (
            <GridContainer>
              <GridItem>
                <Card>
                  <CardHeader color='danger' icon>
                    <CardIcon color='danger'>
                      <Timeline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      Overlapping Summary
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <ChartistGraph
                      data={{
                        labels: labels,
                        series: series,
                      }}
                      type='Pie'
                      options={{ height: '230px' }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              {getPercentofSet()}
            </GridContainer>
          )}

          {isOwner && (
            <GridContainer>
              <GridItem>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ color: 'black' }}
                >
                  Enter email to send invite
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={7}>
                <CustomInput
                  name='email'
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    onChange: (e) => {
                      setReceivers(e.target.value);
                      setAddGuestValue(e.target.value);
                    },
                    value: addGuestValue,
                  }}
                />
                <Button
                  color='info'
                  onClick={() => {
                    dispatch(addGuest(room_id, receivers));
                    setReceivers('');
                    setAddGuestValue('');
                  }}
                  buttonprops={{
                    style: { marginTop: '15px' },
                    color: 'info',
                  }}
                  style={{ margin: '15px' }}
                >
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          )}
        </GridContainer>
      )}

      {userKeys !== null &&
        !aesKeyExists &&
        isOwner &&
        entered && ( //Condition when you are the owner and are waiting for first guest to upload his keys
          <h3>Please wait until the guest has setup the room</h3>
        )}
      {userKeys !== null &&
        !aesKeyExists &&
        !isOwner && ( //Condition for any guest entering first time to upload keys
          <div>
            <GridItem>
              {compareLoader}

              <GridItem>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    // htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Choose Key
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={selectedKeyName}
                    onChange={(e) => {
                      checkKeyId(e.target.value);
                    }}
                    inputProps={{
                      name: 'simpleSelect',
                      id: 'simple-select',
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Key
                    </MenuItem>
                    {userKeys.map((key) => {
                      return (
                        <MenuItem
                          key={key.id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={key.title}
                        >
                          {key.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <Button
                  color='info'
                  onClick={handleGuestSelectKey}
                  buttonprops={{
                    style: { marginTop: '15px' },
                    color: 'info',
                  }}
                  style={{ margin: '15px' }}
                >
                  Submit
                </Button>
                {/*  </Link> */}
              </GridItem>
            </GridItem>
          </div>
        )}
      {userKeys === null && ( //Condition when keys are deleted
        <h3>Go to the settings page and generate new key</h3>
      )}
      {sessionKey === 'dummy' && (
        <h3>Wait for owner to approve you </h3> //Condition when the third guest has uploaded his keys aqnd waiting for the owner to approve
      )}
      {!entered && isOwner && (
        <h3>Please upload your private keys to continue. </h3>
      )}
      {!isOwner && aesKeyExists && !entered && (
        <h3>Please upload your private keys to continue. </h3>
      )}
    </div>
  );
}

export default RoomWigetLocalEncrypt;
